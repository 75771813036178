import { useState, useRef, useEffect } from "react";
import { Container, Row, Button } from "react-bootstrap";
import { QuizChoice } from "../components/quiz_choice";
import { useHeaderTags } from "../components/custom_hook";
import { Subnet } from "../components/subnet";



export const PracticeFlow = (props) => {
    const [showDesc, setShowDesc] = useState(true);
    const level = useRef(1);
    const score = useRef(0);
    const miss = useRef(0);
    const [next, setNext] = useState(false);
    const [question, setQuestion] = useState({ 'text': 'text', 'choices': [{ "text": "a choice" }, { "text": "b choice" }] })
    const [chosen, setChosen] = useState([0, 0, 0]);  // chosen = (select, green, red)
    const [check, setCheck] = useState(false);
    const [ bits, setBits ] = useState();
    const steps = [0, 3, 6, 9, 12, 15, 18, 21, 24, 30];
    const [inSession, setInSession] = useState(true);

    useEffect(() => {
        const getQuestion = async () => {
            try {
                let quest = process.env.REACT_APP_HOST + "/api/v1/question/subnet?class=" + level.current;
                const inbuff = await fetch(quest);
                const d = await inbuff.json();
                if (d.choices.length > 0) {
                    setQuestion(d);
                }
            } catch (error) {
                console.log("No ip returned");
            }
        };
        getQuestion();
    }, [next]);

    const checkQuestion = () => {
        let oldVal = [...chosen];
        question.choices.map((c, i) => {
            let pos = 1 << i;
            if ((chosen[0] & pos) > 0) {
                if (c.point) {
                    oldVal[1] = oldVal[1] | pos;
                } else {
                    oldVal[2] = oldVal[2] | pos;
                }
            } else {
                let clr = pos ^ 0xf;
                oldVal[1] = oldVal[1] & clr;
                oldVal[2] = oldVal[2] & clr;
            }
        });
        if (oldVal[1] > 0 && oldVal[2] === 0) {
            score.current += 1;
            setCheck(true);
            if (level.current > steps.length - 1) {
                setInSession(false);
            }
            if (score.current > steps[level.current]) {
                level.current += 1;
            }
        }
        if (oldVal[2] > 0) {
            miss.current += 1;
        }
        setChosen(oldVal);
    }

    const jumpLevel = (e) => {
        let lvl = level.current + 1;
        level.current = lvl;
        nextQuestion();
    }

    const nextQuestion = () => {
        let getNext = ~next;
        setNext(getNext);
        setCheck(false);
        setChosen([0, 0, 0]);
    }

    const toggleChosen = (i) => {
        let val = chosen[0];
        if (chosen[2] > 0) {
            val = chosen[1];
        }
        let position = 1 << i;
        let newVal = val ^ position;
        let oldArr = [newVal, 0, 0];
        setCheck(false);
        setChosen(oldArr);
    }

    let finalScore = (score.current * 100 / (score.current + miss.current + 0.00001)).toFixed(2)
    useHeaderTags(props);

    return (
        <>
            {inSession ?
                <Container>
                    <div className="docs paper">
                        <h5>{question.text}</h5>
                        <hr />
                        <Row c={chosen}>
                            {question.choices.map((choice, i) => {
                                return <QuizChoice key={i} index={i} option={choice} chosen={chosen} toggle={toggleChosen} bits={setBits} />
                            })}
                        </Row>
                    </div>
                    {!check && <Button className="m-2" disabled={chosen === 0} onClick={checkQuestion}>Check</Button>}
                    {check && <Button className="m-2" onClick={nextQuestion}>Next</Button>}
                </Container> :
                <Container>
                    <div className="docs paper">
                        {finalScore > 85.0 ? <p>
                            Nicely done.  Your final score was {finalScore}.  More levels are in development. Check back soon.
                        </p> :
                            <p>Your final score was {finalScore}. Thats all of the questions we have for now. Come back next week and have another go.
                             More levels are in development.</p>
                        }
                        <></>
                    </div>
                </Container>}
                {question.explain && check?(
                    <Container>
                        <div className="docs paper">
                            {question.explain}
                        </div>
                    </Container>
                ):null} 
            {showDesc && (
                <Container>
                    {check || chosen[2] > 0?
                    <div>
                        <Subnet {...question.subject}/>
                    </div>:
                    <div className="docs paper">
                        <h3>Practice Questions</h3>
                        <p>Improve your subnetting skills with some practice questions. They will become more 
                            difficult as you move along, or you can click on "level" to skip ahead.
                        </p>
                        <p>Correct: {score.current}</p>
                        <p>Miss: {miss.current}</p>
                        <button className="clickable" onClick={jumpLevel}>Level: {level.current}</button>
                    </div>}
                </Container>
            )}
        </>
    )
}