

export function strIp4ToInt(subStr) {
    // return -1 on any error.. 0.0.0.0/0 is valid here.
    let result = -1;
    const net = subStr.split('/|\s')[0];
    const octStr = net.split("\.");
    try {
        const octInt = octStr.map(x => parseInt(x)).filter(y => y >= 0 && y < 256);
        if (octInt.length != 4) {
            return result;
        }
        // we should have an array of 4 integers
        result = 16777216 * octInt[0]
            + 65536 * octInt[1]
            + 256 * octInt[2]
            + octInt[3];
    } catch (err) {
        result = -1;
    }
    return BigInt(result);
}

export function strIp6ToInt(subStr) {
    const octStr = subStr.split(":")
    let octInt = -1n;
    try {
        octInt = octStr.reduce((accum, x, i) => {
                let bigI = BigInt(parseInt(x, 16)) * BigInt((65536 ** (3 - i)));
                return accum + bigI;
        }, 0n);
    } catch (err) {
        return octInt;
    }
    return octInt;
}
