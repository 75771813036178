import { useEffect } from 'react';

export function useHeaderTags(props) {
    useEffect(() => {
        var meta = document.getElementsByTagName('meta');
        const setPageTitle = (title) => { 
          document.title = title;
          meta['og:title'].content = title;
          meta['og:url'].content = document.location.href;
        };
        const setPageDesc = (desc) => {
          document.getElementsByTagName('meta').description.content = desc;
        }
        if (props.title)
            setPageTitle(props.title);
        if (props.desc)
            setPageDesc(props.desc);
       }, []);
}