import { React, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PatternForm } from "../components/forms/pattern_form";
import { useHeaderTags } from  "../components/custom_hook";

export const PatternFlow = (props) => {
  const [pattern, setPattern] = useState();
  useHeaderTags(props);
  return (
    <>
      <PatternForm setPattern={setPattern}></PatternForm>
      <Container>
        <Row>
          {pattern && <Col>
            <pre className="half-panel">
              <code>{pattern}</code>
            </pre>
          </Col>}
          <Col>
            <div className="docs paper">
              <h5>Pattern Config Model</h5>
              <div>Subnet planner for IPv4 and IPv6 used to allocate prefixes over flexible design patterns common in data center, campus and branch networks</div>
              <div className="docs paper">
                <p>
                  <span className="text-bold">pattern:</span> (Optional: default = "default") Label
                  used to identify this pattern. Future use where patterns can
                  connect and reference each other
                </p>
                <p>
                  <span className="text-bold">prefix:</span> (Mutually exclusive with{" "}
                  <code>prefixes</code>) The single subnet block that will be
                  used to assign subnet to all links in the pattern{" "}
                </p>
                <p>
                  <span className="text-bold">prefixes:</span>(Mutually exclusive with{" "}
                  <code>prefix</code> A list of prefixes that can be assigned
                  to links within a specific mesh network
                </p>
                <div className="tab">
                  <p>
                    <span className="text-bold">prefix:</span>(Mandatory) Aggregate used to assign
                    prefixes to links to a mesh network. If the the list has a
                    single prefix, then it is the default prefix and does not
                    need a label. Similar to just assigned a prefix to the
                    pattern directly. When multiple meshes are declared, each
                    mesh will be assigned an aggregate from this prefix
                  </p>
                  <p>
                    <span className="text-bold">tag:</span>(Mandatory when 2 or more prefixes are in
                    list) Tags are used to bind a prefix and its properties to
                    a mesh
                  </p>
                  <p>
                    <span className="text-bold">p2p_mask:</span>(Optional: default = 30 for IPv4, 64
                    for IPv6) The mask to be used when allocation prefixes
                    from the aggregate on point to point links.
                  </p>
                </div>
                <p>
                  <span className="text-bold">topo:</span>(Mandatory) A list of levels found within
                  the pattern. Levels aggregate East / West flows, and
                  transition north / south traffic.
                </p>
                <div className="tab">
                  <p>
                    <span className="text-bold">level:</span> (Mandatory) the level's name, and is
                    also the reference label used when connecting to other
                    lavels
                  </p>
                  <p>
                    <span className="text-bold">size:</span> (Mandatory) the number of network
                    devices that are present at this level. In the output,
                    each device is assigned a hostname that consists of the
                    level name appended with the devices index number in the
                    list
                  </p>
                  <p>
                    <span className="text-bold">name:</span> (Optional: default = "no_name") the
                    devices link name when connecting to other levels. The
                    name is predended with the interface sequence number in
                    the final output
                  </p>
                </div>
                <p>
                  <span className="text-bold">connect:</span>(Mandatory) A list of connections that
                  attach one level to another level. This creates a mesh of
                  links used to interconnect levels in the pattern
                </p>
                <div className="tab">
                  <p>
                    <span className="text-bold">levels:</span>(Mandatory) A list with exactly 2
                    elements, consisting of the level names that will be
                    attached through the link mesh
                  </p>
                  <p>
                    <span className="text-bold">type:</span>(Mandatory) The type of mesh that will
                    be created. Currently only "<code>p2p</code>" is
                    supported. P2P meshes create a link from every device in
                    one level to every device in the ohter level
                  </p>
                  <p>
                    <span className="text-bold">addr_tag:</span>(Optional) Can be either a list for
                    tags, or a single tag. Binds a prefix to the level mesh
                    network. Lists can be used to dual stack IPv4 and IPv6 on
                    a link
                  </p>
                </div>
                <p>
                  Here is an example of a complete model with three levels. The mesh
                  between the super spine and spine is dual stacked, using the
                  10 address space. The mesh between the spine and leaf level
                  runs IPv4 only from the 192.168.0.0 space. The example is fictional. It would be unusual to
                  dual stack only one level, but the model does support it.
                </p>
                <p className="tab">
                  <pre>
                    <code>
                      pattern: demo
                      <br />
                      prefixes:
                      <br />
                      - prefix: 10.180.19.0/21
                      <br />
                      &nbsp;&nbsp;tag: ssp_ipv4
                      <br />
                      &nbsp;&nbsp;p2p_mask: 29
                      <br />
                      - prefix: 192.168.0.0/16
                      <br />
                      &nbsp;&nbsp;tag: ipv4
                      <br />
                      &nbsp;&nbsp;p2p_mask: 31
                      <br />
                      - prefix: 2000:1000:5000::/56
                      <br />
                      &nbsp;&nbsp;tag: ipv6
                      <br />
                      &nbsp;&nbsp;p2p_mask: 127
                      <br />
                      topo:
                      <br />
                      - level: superspine
                      <br />
                      &nbsp;&nbsp;size: 2<br />
                      &nbsp;&nbsp;name: SS
                      <br />
                      - level: spine
                      <br />
                      &nbsp;&nbsp;size: 2<br />
                      &nbsp;&nbsp;name: SP
                      <br />
                      - level: leaf
                      <br />
                      &nbsp;&nbsp;size: 3<br />
                      &nbsp;&nbsp;name: LF
                      <br />
                      connect:
                      <br />
                      - levels: [superspine, spine]
                      <br />
                      &nbsp;&nbsp;type: p2p
                      <br />
                      &nbsp;&nbsp;addr_tag: [ssp_ipv4, ipv6]
                      <br />
                      - levels: [spine, leaf]
                      <br />
                      &nbsp;&nbsp;type: p2p
                      <br />
                      &nbsp;&nbsp;addr_tag: ipv4
                      <br />
                    </code>
                  </pre>
                </p>
                <p>Note: the model shown above is configured to use a default /127 mask on IPv6 p2p links. The RFCs have shifted a bit on guidance for IPv6 on P2P links from eitehr a /126, /127 or /64. This tool
                  chooses the middle ground and will reserve a /64 for the subnet under the aggregate, but then will use the configured mask on the links.</p>
                <p>also: IPv6 subnets with a /127 mask, or IPv4 with a /31 mask will assign the hosts IPs on 0 and 1. When the masks allows .1 and .2 to be assigned, the tool takes this approach even though IPv6 allows :0 as a host under any mask. As with all things,
                  there are pros and cons and future updates may include additional knobs to fine tune the behavior. The motivation for this initial choice is network supportability. Entry level staff
                  working on the network in the middle of the night are more likely to run into trouble with /127 addresses or :: hosts and this can cascade into larger problems or extend troubleshooting sessions</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
