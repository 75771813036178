import { useState, useEffect, useContext, Fragment } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { PrefixForm } from "../components/forms/prefix_form";
import { useHeaderTags } from "../components/custom_hook";
import { UserContext } from "../components/user_context";

export const PrefixFlow = (props) => {
  const [prefixes, setPrefixes] = useState();
  const [showDesc, setShowDesc] = useState(true);
  const [optimize, setOptimize] = useState(true);
  const [loadUp, setLoadUp] = useState("");
  const [prePref, setPrePref] = useState("");
  const prfxImg = process.env.REACT_APP_HOST + "/img/prefix_img1.svg";
  const yt1 = process.env.REACT_APP_HOST + "/img/prefix_tool_1.jpg";
  const yt2 = process.env.REACT_APP_HOST + "/img/prefix_tool_2.jpg";

  useHeaderTags(props);
  let userContext = useContext(UserContext);

  const handlePrefs = (resp) => {
    if (!resp.ok) {
      const error = resp.statusText;
      return Promise.reject(error);
    } else
      return resp.text().then(text => {
        const data = text && JSON.parse(text);

      });
  }

  useEffect(() => {
    const getPrefixContext = async () => {
      try {
        let prefUri = process.env.REACT_APP_HOST + "/api/v1/userPref";
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({ 'email': userContext.email }),
        };
        const inbuff = await fetch(prefUri, requestOptions).then(handlePrefs);
      } catch (error) {
        console.log("No preference");
      }
    }
    if (userContext.valid === true) {
      getPrefixContext();
    }

  }, [])

  const nSpaces = (n) => {
    if (n === 0) {
      return;
    }
    return (
      "  ".repeat(n) + "∟-"
    );
  };

  const downloadTemplate = () => {
    // text content
    const templateText = Array.from(document.getElementById("templateId").children).map(txt => {
      return txt.textContent + "\r\n";
    })
    // file object
    const file = new Blob(templateText, { type: 'text/plain' });
    // anchor link
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "bitlug_template.txt";
    // simulate link click
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const nMerged = (n) => {
    if (n === 0) {
      return;
    }
    if (n === 1) {
      return "/";
    }
    return "\\";
  };

  let sizes = [];
  let subTarg = null;
  if (prefixes && prefixes.precalc) {
    Object.values(prefixes.precalc).forEach(row => {
      sizes.push(row.length);
    });
  }
  if (sizes.length === 1) {
    subTarg = Object.values(prefixes.precalc)[0][0];
  }
  const followSub = (e) => {
    let tar = e.currentTarget.dataset.target.replace("/", "_");
    window.location.href = "/act/app/subnet/" + tar;
  }

  const followPrefix = (e) => {
    let k = Object.keys(prefixes.precalc);
    let formData = prefixes.precalc[k][0];
    setLoadUp(formData);
  }

  const clearLoadNow = () => {
    setLoadUp("");
  }

  const followTree = (e) => {
    let tar = e.currentTarget.dataset.target.replace("/", "_");
    let fromto = tar.split("-");
    if (fromto.length === 2)
      window.location.href = "/act/app/tree?from=" + fromto[0] + "&to=" + fromto[1];
  }

  let single = prefixes && prefixes.subnets.length === 0;
  let countTot = 0;
  if (prefixes && prefixes.precalc) {
    countTot = Object.entries(prefixes.precalcSizes).reduce((accum, current) => current[1] >= 0 ? BigInt(current[1]) + BigInt(accum) : 0, BigInt(0));
  }

  let sortedSubs = [];
  if (!optimize && prefixes) {
    sortedSubs = prefixes.subnets.toSorted((s1, s2) => s1.index - s2.index);;
  }

  return (
    <>
      <Container>
        <PrefixForm
          setPrefixes={setPrefixes}
          showDesc={setShowDesc}
          optimize={optimize}
          setOptimize={setOptimize}
          loadNow={loadUp}
          clrLoadNow={clearLoadNow}
        ></PrefixForm>
      </Container>
      {showDesc && (
        <Container>
          <div className="docs paper">
            <h3>Prefix-List Calculator</h3>
            <p>If a single prefix-list element is used, then the prefix calculator is applied:
              <li>Supports both IPv4 and IPv6 subnets</li>
              <li>Generates a list of matched subnets.</li>
              <li>Accepts le, ge and eq prefix length qualifiers</li>
              <li>Best match for a start and end subnet, 192.168.1.0/24-192.168.5.0/26</li>
              <li>Best match for a start and end address, 192.168.1.0-192.168.5.63</li>
            </p>
            <h3>Prefix-List Optimizer</h3>
            <p>If more than one subnet or prefix element is used, then the prefix optimizer is applied
              <li>Supports both IPv4 and IPv6 subnets</li>
              <li>Converts a list of subnets into an optimized prefix-list</li>
              <li>Sorts subnets into their natural order</li>
              <li>Identifies overlapping subnets and prefixes</li>
              <li>Converts network masks to wildcards or CIDR notation</li>
              <li>Accepts subnets imbedded in text to allow text scrapping.</li>
              <li>Re-order permit and denys to prevent prefix-list policy shadows</li>
            </p>
            <h3>Overview</h3>
            <p>Prefix-List are used to filter routes. They are able to match on the mask length, providing better control compared to traditional ACLs that are
              better suited for traffic filtering. Prefix-lists are typically found on BGP neighbor statements to control inbound and outbound route propogation.
              They may also be found in route distribution between protocols.  Prefix-lists match the prefix length using optional le, ge or eq values.<br />
              Consider three prefix elements:
              <br /><code>172.20.0.0/16</code> --  The prefix CIDR must be exactly /16, this is the only route allowed
              <br /><code>172.20.0.0/16 le 29</code> -- less then or equal to 29 means the prefix CIDR must less then /29 until it reaches the prefix's CIDR of 16.  30 is larger than 29 so 172.20.0.0./30 would not match
              <br /><code>172.20.0.0/16 ge 22 le 29</code> -- The prefix must be a member of 10.31.0.0/16 with a CIDR between /22 and /29.  Both 172.26.0.0/22 and 172.26.0.0/29 would be examples
              <br /><code>172.20.0.0/16 eq 24</code> -- any /24 prefix that falls under 172.20.0.0/16, for example 172.20.0.0/24, 172.20.1.0/24, 172.20.2.0/24.. etc.
            </p>
            <div>This illustration highlights the behavior:
              <div className="center m-3"><img src={prfxImg} alt="Prefix CIDR Map" width={props.width ? "65%" : "%100%"} viewBox="0 0 430 120" style={{ 'maxWidth': '1040px' }}></img></div>
              <p>Its important to rememeber that ge and le refer only to the mask values and not the subnet size itself.  In this context, less then or equal to 24  (le 24) matches /23 subnets that are actually larger in host counts than /24 subnet.
                This can be confusing at first. Because IPv4 subnet size is 2^<sup>(32 - mask)</sup>, a smaller mask value results in a larger subnet</p>
            </div><p>
              Over time, additional lines are often added to a prefix list, sometimes without consideration of what is already included. The list will begin to have overlaps, inclusions and similar issues.
              This tool can drill through a long prefix lists and identify where the prefix list can be improved. Optmiziations are done using the more common le value and not ge or eq.
            </p>
            <h3>Videos</h3>
            <div className="m-2">
              <a href="https://youtu.be/EiKRAcC8Jcs"><img width="180px" alt="youtube video part 1" src={yt1}></img></a>
              <span className="m-3">Explains some of the features when using the prefix tool as a calculator.</span>
            </div>
            <div className="m-2">
              <a href="https://youtu.be/Wi_XICbe6GE"><img width="180px" alt="youtube video part 2" src={yt2}></img></a>
              <span className="m-3">Use the optimizer with templates to create a prefix-list that blocks bogons</span>
            </div>

            <h3>Usage</h3>
            <p>
              Enter a list of subnets, one per line. They can be in either CIDR
              (<code>192.168.1.0/24</code>) or mask (<code>192.168.1.0 255.255.255.0</code>) format.  The tool will find adjacent and overlapping
              subnets and combine them to the best possible prefix element. The result displays three columns:
              <li>Prefix filter format (<code>192.168.0.0/16 le 24</code>) with an accumulated total subnet size</li>
              <li>Summary routes with a full mask (<code>192.168.0.0 255.255.0.0</code>)</li>
              <li>Extracted subnets sorted, with overlaps identified</li></p><p>  The subnet can be imbedded in other text, for
                example<span className="d-block"><pre><code>B  10.207.0.0/17 [20/0] via 10.128.133.82, 5w6d</code></pre></span>
              In this case of this route, the route prefix is seen first and captured, while the next-hop host is ignored.
              Anything without a mask or cidr is considered a host.</p>
            <p>You can also parse objects from an object group to organize the object elements, and optimize for duplicates and adjacent subnets<br />
              <pre><code>network-object 10.219.170.0 255.255.254.0<br />
                network-object 10.219.170.0 255.255.255.0<br />
                network-object 10.219.171.0 255.255.255.0</code></pre>
              <br />The object above can be reduced to just the first line which overlaps the others.
            </p><p>
              Finally the tool can optimize an existing prefix list.  For example:<br />
              <pre><code>seq 10 permit 10.104.0.0/16 le 24<br />
                seq 20 permit 10.105.0.0/16 le 24<br />
                seq 30 permit 10.105.81.192/26</code><br />
                Can be simplified to <code>10.104.0.0/15 le 26</code></pre>
            </p>
            <p>
              As IPv6 example, these 5 prefixes will summarize into 2.  The first 4 collapse into a single /62, while the last does not share a bit boundary with the others <br />
              <code>
                2000:1000:500:250::/64<br />
                2000:1000:500:251::/64<br />
                2000:1000:500:252::/64<br />
                2000:1000:500:253::/64<br />
                2000:1000:500:254::/64
              </code>
            </p>
            <h4>Policy Shadowing</h4>
            <p>
              If a list includes permit and deny statements, the tool will become aware of overlapping but out of order policy. Prefix-lists are
              sequenced where each line is considered in turn until a match is found. If you permit 10.0.0.0/8 on the first line, then deny 10.10.0.0/16
              on the second line, the second line will be shadowed by the first. Specifically, everything will be permitted and the deny will
              have no effect.
              <br />
              <code>
                permit 10.0.0.0/8 le 22<br />
                deny 10.10.0.0/16 le 24<br />
              </code>
            </p>
            <p>
              The tool will identify this scenario and re-sequence the deny statement to proceed the permit.  When this re-sequencing
              effectively changes the resulting policy, the change will be highlighted in orange.
              <br />
              <code>
                <span className="text-deny">deny 10.10.0.0/16 le 24</span><br />
                permit 10.0.0.0/8 le 22<br />
              </code>
            </p>
            <p>It is also possible for a broad deny to shadow a more specific permit. Again, the tool assumes this was
              unintentional and will re-sequence the prefilx-list to remove the shadow.
            </p>
            <p>
              Prefix-lists include an implicit deny at the bottom of the list. If a policy check makes it to the bottom of the prefix-list
              and has not been explicitly permitted, then it is dropped implicitly. The exception is an empty prefix-list with no policy elements. In this
              case, no policy is applied which is effectively permits everything. This tool moves "clean" deny statements to the bottom.
              A clean statement does not overlap any other policy element and a change in sequence would not change the effective policy. <br />
              <code>
                deny 192.168.1.0/24 le 28<br />
                permit 10.0.0.0/8 le 32<br />
                <span className="text-secondary">implicit deny 0.0.0.0/0 le 32</span>
              </code><br /></p><p>
              Can be re-written as <br />
              <code>
                permit 10.0.0.0/8 le 32<br />
                deny 192.168.1.0/24 le 28<br />
                <span className="text-secondary">implicit deny 0.0.0.0/0 le 32</span>
              </code><br />
            </p><p>
              These denys could be removed
              without changing the policy, although it is not uncommon to leave explicit deny's if only to track counters. In the example above, the tool will sort non-conflicting policy and will place the 10 network above the 192 network.
            </p><p>
              The tool will not re-order permits and denys where the current ordering does not create shadows. However, the tool will collect
              similar elements so that the policy intent is easier to read.<br />
              <code>
                deny 192.168.1.0/24<br />
                permit 10.130.0.0/16 le 24<br />
                deny 10.130.10.0/24<br />
                permit 192.168.0.0/16 le 24<br />
              </code></p>
            <p>
              The prefix-list above has two problems. First there is a shadow over deny 10.130.10.0/24. Second, it is not apparent that a single /24 is being excluded from the 192.168.0.0/16 supernet. The tool will re-sequence the prefix-list to:<br />
              <code>
                <span className="text-deny">deny 10.130.10.0/24</span><br />
                permit 10.130.0.0/16 le 24<br />
                deny 192.168.1.0/24<br />
                permit 192.168.0.0/16 le 24<br />
              </code>
            </p>
            <p>The first deny is highlighted in orange to indicate this re-sequence will result in a policy change to correct the previous shadow. Previously
              this deny had no affect.  Deny 192.168.1.0/24 has been moved down
              to proceed permit 192.168.0.0/16 le 24. This is not a change in policy, but makes the policy intent easier to read.
            </p>
            <h4>Test for a Subnet</h4>
            <p>You can provide a subnet that can be tested against the prefix list. If the prefix list is a single line, the tool will determine if that line matches or does not match the subnet.
              If the prefix list is multiple lines, all matching lines will be indicated in the output with a &lt;- match label.  For example, if your prefix-list is</p>
            <pre><code>
              ipv6 prefix-list WAN-IPV6 seg 10 deny 1000:2000:400::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 20 deny 1000:2000:400:100::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 30 deny 1000:2000:400:200::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 40 permit 1000:2000::/32 ge 48 le 64<br/>
            </code></pre>
            <p>and your match subnet is <code>1000:2000:400:200::/64</code></p>
            <p>The tool will display the output</p>
            <pre><code>
              deny 1000:2000:400::/55 le 56<br />
              deny 1000:2000:400:200::/56<br />
              permit 1000:2000::/32 ge 48 le 64 &lt;--Matches subnet<br />
            </code></pre>
            <p>Indicating that only the action on the last line of the prefix list will be applied to the subnet.  Note that prefix-lists only match subnets. If you provide a host address, without a mask value, 
              as the match subnet, the tool will append a /32 mask onto the subnet.  If you prefix-list does not have an element that will cover the /32 mask value, then it won't match.
            </p>
            <h4>Prefix Labels</h4>
            <p>
            When parsing a prefix list, you can label the prefix.  This label is then displayed in the subnet results. Only one label can be applied. The label is seperated by either a space or comma, and as a result, the label can not contain these characters.  For example:
            <pre><code>192.168.1.0/24 SFO<br />
                10.130.0.0/16 JFK<br />
                10.130.10.0/24 LGA<br />
                192.168.0.0/16 ATL<br />
                </code></pre>
                This is useful when examining overlappping prefixes. Note that when using permit or deny, the parser will interpret those as the tag.  Prefix labels do not work with le, ge, or eq.  In the example above, the output is:
                <pre><code>
                10.130.0.0/16 JFK<br/>
                   &nbsp; ∟-10.130.10.0/24 LGA<br/>
                192.168.0.0/16 ATL<br/>
                 &nbsp; ∟-192.168.1.0/24 SFO<br/></code></pre>
            </p>
            
            <h4>Convert subnet mask to wildcard</h4>
            <p>To convert a list of subnets with a dotted mask notation into wildcards, select the <strong>Summaries as wildcards</strong>. The results will be in column 2 of the output. The wildcard format only applies to IPv4 subnets</p>
            <h3>Templates</h3>
            <p>The prefix tool now supports templates. These are used to embed results in a text file such as a configuration snippet. Because the input also supports embedded subnets, templates are a powerful way to quickly translate between formats. The input can be a straight list of subnets, or the output
              of a show command. The subnets are extracted, optimize and then used to populated variables in the template.
            </p>
            <p>Some examples can highlight how they can be used. Assume the following output:</p>
            <pre><code>
              Network          Next Hop            Metric LocPrf Weight Path<br />
 *>   10.123.77.0/25   10.123.64.22            50             0 65123 ?<br />
 *>   10.123.77.128/25 10.123.64.22            50             0 65123 ?<br />
 *>   10.123.90.128/25 10.123.64.22            50             0 65123 ?<br />
 *>   10.123.93.0/24   10.123.64.22            50             0 65123 ?<br />
 *>   10.123.95.0/26   10.123.64.22            50             0 65123 ?<br />
 *>   10.123.95.64/26  10.123.64.22            50             0 65123 ?<br />
 *>   10.123.95.128/27 10.123.64.22            50             0 65123 ?<br />
 *>   10.123.95.168/29 10.123.64.22            50             0 65123 ?<br />
 *>   10.123.95.176/28 10.123.64.22            50             0 65123 ?<br />
 *>   10.123.95.192/27 10.123.64.22            50             0 65123 ?<br />
 *>   10.123.95.224/27 10.123.64.22            50             0 65123 ?<br />
 *>   172.20.143.0/25  10.123.64.22            50             0 65123 ?<br />
 *>   172.20.127.192/26<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.123.64.22            50             0 65123 ?<br />
 *>   172.22.50.0/24   10.123.64.22            50             0 65123 ?<br />
              <br />
              Total number of prefixes 14<br />

            </code></pre>
            <p>If we would like to create an Cisco prefix-list, we would use the template below:</p>
            <pre><code>
              ip prefix-list THE_NAME seq &#36;&#123;index&#125;0 permit &#36;&#123;prefix&#125;
            </code></pre>
            <p>This will generate a prefix-list that can be used to filter routes from the BGP neighbor to those seen above. That is useful if we'd like the admin of AS65123
              to coordinate any changes in their adverstisements
            </p>
            <pre><code>
              ip prefix-list THE_NAME seq 10 permit 10.123.64.22<br />
              ip prefix-list THE_NAME seq 20 permit 10.123.77.0/24 le 25<br />
              ip prefix-list THE_NAME seq 30 permit 10.123.90.128/25<br />
              ip prefix-list THE_NAME seq 40 permit 10.123.93.0/24<br />
              ip prefix-list THE_NAME seq 50 permit 10.123.95.0/25 le 26<br />
              ip prefix-list THE_NAME seq 60 permit 10.123.95.128/27<br />
              ip prefix-list THE_NAME seq 70 permit 10.123.95.168/29<br />
              ip prefix-list THE_NAME seq 80 permit 10.123.95.176/28<br />
              ip prefix-list THE_NAME seq 90 permit 10.123.95.192/26 le 27<br />
              ip prefix-list THE_NAME seq 110 permit 172.20.127.192/26<br />
              ip prefix-list THE_NAME seq 120 permit 172.20.143.0/25<br />
              ip prefix-list THE_NAME seq 130 permit 172.22.50.0/24<br />
            </code></pre>
            <p>Notice that the prefixes have been optimized by combining adjacent subnets. You can disable optimization when using templates by unchecking the optimize option</p>
            <p>In another use case, you may also want to create an anti-spoof ACL to ensure that only packets sourced from these subnets are accepted over this link. Simply update the template. The exmample includes a sequence number that increments by 5, starting from 20</p>
            <pre><code>
              &#36;&#123;seq5+20&#125; permit ip &#123;acl&#125; any</code></pre>
            <p>That will result in the output shown below</p>
            <pre><code>
              20 permit ip 10.123.64.22 0.0.0.0 any<br />
              25 permit ip 10.123.77.0 0.0.0.255 any<br />
              30 permit ip 10.123.90.128 0.0.0.127 any<br />
              35 permit ip 10.123.93.0 0.0.0.255 any<br />
              40 permit ip 10.123.95.0 0.0.0.127 any<br />
              45 permit ip 10.123.95.128 0.0.0.31 any<br />
              50 permit ip 10.123.95.168 0.0.0.7 any<br />
              55 permit ip 10.123.95.176 0.0.0.15 any<br />
              60 permit ip 10.123.95.192 0.0.0.63 any<br />
              70 permit ip 172.20.127.192 0.0.0.63 any<br />
              75 permit ip 172.20.143.0 0.0.0.127 any<br />
              80 permit ip 172.22.50.0 0.0.0.255 any<br />
            </code></pre>
            <p>This type of conversion between formats can be time consuming and error prone when done by hand.</p>
            <p>note: depending on the vendor, you can resequence an exsiting ACL.  In the case of Cisco, the command is <pre><code>ip access-list resequence _NAME _START _INCREMENT</code></pre></p>
            <p>If the input text includes 'permit' or 'deny', you can use a template to redefine the verb or keep the verb but change the format.  In this case, use  &#36;&#123;subnet&#125; rather than  &#36;&#123;prefix&#125; since prefix will
              also use the default mappings. For example, you could create a structured model with a template such as:
            </p>
            <pre><code>
              &#123;<br />
              subnet&#58; &#36;&#123;subnet&#125;,<br />
              action&#58; &#36;&#123;allow&#58;block&#125;<br />
              &#125;<br /></code></pre>
            when this is applied to an input of:
            <pre><code>
            </code>permit 192.168.1.0/24<br />
              deny 192.168.2.0/24</pre>
            Will result in an output<br />
            <pre><code>
              &#123;<br />
              subnet&#58; 192.168.1.0 255.255.255.0,<br />
              action&#58; allow<br />
              &#125;<br />
              &#123;<br />
              subnet&#58; 192.168.2.0 255.255.255.0,<br />
              action&#58; block<br />
              &#125;<br />
            </code></pre>
            <p>Of course you can customize the template as needed to create a specific output format. When using permits, the templates will not carry the color coding indicating realignment that was required to unblock shadowed policy. This coding is presented below the template output as explained earlier as policy shadowing </p>

            <h5>Tip:</h5>
            <div>The parser finds the first subnet on each line of text, but is not interpretting the text in any context. In the example above, there are two subnets in each row: the prefix being routed
              and the originator of the advertisement. When the parser reads this text, the prefix is used and the orginiator is ignored. Usually this works out well because column one of most tables
              is the subnet we are interested in.  But notice on the 2nd to last line. Here the originator of 172.20.127.192/26 appears on its own line in an effort to keep the table columns aligned. This means the bitlug parser will include this IP address when
              populating templates. This highlights one of the challenges when automation is used to parse values out of show commands. Usually its easy to identify where this occured.
              But in the case of the anti-spoof ACL, this address should be permitted.
            </div>
            <div>&nbsp;</div>
            <h5>Important!</h5>
            <p>
              When using templates, <strong>ALWAYS</strong> confirm the output is exactly correct before applying it to any device configuration.
            </p>

            <h3>Aggressive Optimization</h3>
            <p>The optimizer's default algorithm aggresively combines perfixes to minimize the resulting prefix filter.  It operates strictly with ge and loose with le.
              This means that le will be extended to cover smaller prefixes, while ge will remain fixed.  Two examples will illustrate this behavior. If you have a route filter using a prefix-list shown below:</p>
            <pre><code>
              permit 10.20.30.0/24<br />
              permit 10.20.30.24/30<br /></code></pre>
            <p>This filter will accept match two prefixes, however the first happens to overlap the second. When aggressively optimized, the result will be:</p>
            <pre><code>permit 10.20.30.0/24 le 30</code></pre>
            <p>Here the same two prefixes are matched and additional prefixes that were not initially permitted are also matched, for example 10.20.30.128/25. </p>
            <p>Lets look at another example. In this case, an existing prefix-list: </p>
            <pre><code>
              10.31.0.0/16 ge 22 le 25<br />
              10.31.0.0/28<br />
              10.31.2.0/18<br />
            </code></pre>
            <p>It would be possible to condense this into a single line</p> <pre><code>10.31.0.0/16 ge 18 le 28</code></pre>
            <p>by adjusting the GE value on the first line to accomodate the mask seen on the last line and extneding the le
              value from the current 25 to 28 which would then match the 2nd line.
              However, the adustment of ge would change the matched summaries which is unlikely the intent.  Instead, only le is adjusted and the result will becomes:</p>
            <pre><code>
              10.31.0.0/16 ge 22 le 28<br />
              10.31.2.0.18<br /></code></pre>
            <p>The tool will not create a prefix with ge, for example <code>10.31.0.0/16</code> and <code>10.30.0.0/16</code> will become <code>10.30.0.0/15 le 16</code></p>
            <p>Aggressive optimization may be disabled.  In this mode, the prefixes will not be optmized unless the match is explicit. Lets change the example above to demonstrate this
              by adjusting the le value on the first line, and the mask on the second line.  In this case, the 2nd line provides no value because the first line already matches the
              same prefix.
            </p>
            <pre><code>
              10.31.0.0/16 ge 22 le 28<br />
              10.31.0.0/25<br />
              10.31.2.0/18<br />
            </code></pre>
            <p>The order is not important.  The same results will be shown regardless as long as the match doesn't include an permit or deny action.  For example</p>
            <pre><code>
              deny 10.31.0.0/16 ge 22 le 28<br />
              permit 10.31.0.0/25<br />
              deny 10.31.2.0/18<br />
            </code></pre>
            <p>Lets look at an advanced case with IPv6.  Lets assume the enterprise has been assigned a /32 by ARIN, and we want to create a prefix list to only accept prefixes from this 32 down to a /64.
              Out initial prefix list is pretty easy.  We will include the deny at the bottom so we can track hit counters.</p>
            <pre><code>
              ipv6 prefix-list WAN-IPV6 seg 10 permit 1000:2000::/32 le 64<br />
              ipv6 prefix-list WAN-IPV6 seg 20 deny ::/0<br />
            </code></pre>
            <p>But lets change this. Lets say we've decided that we will carve the space up into /48s to cover the branch networks. Because of this, we shouldn't accept the /32 from any branch, but rather only /48's. So now are prefix-list becomes</p>
            <pre><code>
              ipv6 prefix-list WAN-IPV6 seg 10 permit 1000:2000::/32 ge 48 le 64<br />
              ipv6 prefix-list WAN-IPV6 seg 20 deny ::/0<br />
            </code></pre>
            <p>OK, so far so good.  Lets expand things a bit and define a few DC subnets that should not be seen coming from the WAN.  We will block these.  Because prefix list are considered in a top down fasion, we will need deny's to come
              before the permit.  So here we are:</p>
            <pre><code>
              ipv6 prefix-list WAN-IPV6 seg 10 deny 1000:2000:400::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 20 deny 1000:2000:400:100::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 30 deny 1000:2000:400:200::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 40 permit 1000:2000::/32 ge 48 le 64<br />
              ipv6 prefix-list WAN-IPV6 seg 50 deny ::/0<br />
            </code></pre>
            <p>If you put this into the tool, it will combine the first two lines, so now we have:</p>
            <pre><code>
              ipv6 prefix-list WAN-IPV6 seg 10 deny 1000:2000:400::/55 le 56<br />
              ipv6 prefix-list WAN-IPV6 seg 30 deny 1000:2000:400:200::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 40 permit 1000:2000::/32 ge 48 le 64<br />
              ipv6 prefix-list WAN-IPV6 seg 50 deny ::/0<br />
            </code></pre>
            <p>But wouldn't you know it, when we put this prefix filter on our WAN, someone had accidently assigned a prefix in one of the branches that should have belonged to the datacenter.  So now we have to
              carve an exception for 1000:2000:400:185::/64. Lets preten we don't really know where to put it, so to play it safe, we just put it right at the front.  Like this:</p>
            <pre><code>
              ipv6 prefix-list WAN-IPV6 seg 5 permit 1000:2000:400:185::/64<br />
              ipv6 prefix-list WAN-IPV6 seg 10 deny 1000:2000:400::/55 le 56<br />
              ipv6 prefix-list WAN-IPV6 seg 30 deny 1000:2000:400:200::/56<br />
              ipv6 prefix-list WAN-IPV6 seg 40 permit 1000:2000::/32 ge 48 le 64<br />
              ipv6 prefix-list WAN-IPV6 seg 50 deny ::/0<br />
            </code></pre>
            <p>This looks pretty good, except that if you put this into the tool, bitlug will remove the permit.  Why?  Well, the deny statements we have don't actually block a prefix with a /64.  Only prefixes between /55 and /56.
              The permit /64 isn't actually doing anything because it is already permitted on line 40.  Our mistake here is the denys need to include all of the prefixes that might be found in the datacenter, not just the assigned summary blocks.
              So lets update things a bit. </p>
            <pre><code>
              ipv6 prefix-list WAN-IPV6 seg 5 permit 1000:2000:400:185::/64<br />
              ipv6 prefix-list WAN-IPV6 seg 10 deny 1000:2000:400::/55 le 128<br />
              ipv6 prefix-list WAN-IPV6 seg 30 deny 1000:2000:400:200::/56 le 128<br />
              ipv6 prefix-list WAN-IPV6 seg 40 permit 1000:2000::/32 ge 48 le 64<br />
              ipv6 prefix-list WAN-IPV6 seg 50 deny ::/0<br />
            </code></pre>
            <p>
              But now we want to declare that 1000:2000:400::/48 le 64 should also be blocked from the WAN.  To keep things easy, lets just put it at the bottom (or top) and let bitlug figure out where to place it. In this case, it aggressively combined
              the /48 le 64 we addedd with deny 1000:2000:400::/55 le 128 to create:

            </p>
            <pre><code>
              permit 1000:2000:400:185::/64<br />
              <span className="text-deny">deny 1000:2000:400::/48 le 128<br /></span>
              deny 1000:2000:400:200::/56 le 128<br />
              permit 1000:2000::/32 ge 48 le 64<br />
              deny 0::/0</code></pre>
            <p>If we disable the aggressive optimization, the /48 le 64 will not join with the /55 le 128.  Instead, the larger /48 will be placed on top of the /56. Both are deny's so the order is not too important. The algorithm prefers to place larger prefixes above smaller prefixes when dealing with partial overlaps. </p>
          </div>
        </Container>
      )
      }
      {
        !showDesc && <>
          {prefixes.errMsg &&
            <Container><div className="err">{prefixes.errMsg}.</div></Container>}
          {prefixes.message &&
            <Container><div className="msg"><code>{prefixes.message}</code></div></Container>}
          {single ? <Container>
            <Row className="mt-3 p-4">
              <Col>
                {prefixes.wildRange ? <h5>Suggested prefix element</h5> : <h5>
                  First and last 6 matched subnets for each prefix length.
                </h5>}
                {prefixes.error ? null :
                  <div className="half-panel"><Table variant='light' size='sm'>
                    <thead><tr>
                      <th>CIDR</th><th>Count</th><th>Samples</th></tr></thead>
                    <tbody className="font-monospace fs-6">
                      {Object.keys(prefixes.precalc).map((key, k) => {
                        return <Fragment key={key}><tr className="border-bl"><td>/{key}:</td><td>{prefixes.precalcSizes[key] > 0 && prefixes.precalcSizes[key]}</td><td></td></tr>
                          {prefixes.precalc[key].map((r, i) => {
                            return <tr key="r"><td className={i === 5 && sizes[k] > 11 ? "border-split" : ""} colspan="2">&nbsp;</td><td>
                              {prefixes.linkTo ? (
                                <a onClick={followPrefix} href="#" className="clickable">{r}</a>
                              ) : r}
                            </td></tr>
                          })}</Fragment>
                      })
                      }
                      {countTot > 0 && <tr className="border-bl"><td>Total:</td><td>{countTot.toString()}</td><td></td></tr>}</tbody></Table></div>}
              </Col>
              {prefixes.wildRange && <Col>
                <h5>ACL elements, exact for range</h5>
                <div className="half-panel">
                  <code style={{ "width": "75%" }}>
                    {prefixes.wildRange.map(r => {
                      return <div key={r}>{r}</div>
                    })}
                  </code></div></Col>
              }</Row>
            {Object.keys(prefixes.precalc).length === 1 && !prefixes.refTool &&
              <Button onClick={followSub} className="blbutton" data-target={subTarg}>Subnet Calc for &nbsp;<span className="text-light">{subTarg}</span></Button>}
            {Object.keys(prefixes.precalc).length === 1 && prefixes.refTool === "Tree" &&
              <Button onClick={followTree} className="blbutton" data-target={prefixes.line}>Tree Tool &nbsp;<span className="text-light">{prefixes.line.replaceAll("_", "/")}</span></Button>}
          </Container> :

            <Container>
              {prefixes.template &&
                <Row className="full-panel">
                  <div className="btnDiv">
                    <button id="downloadBtn" className="btn btn-normal btn-outline-primary" value="download" onClick={downloadTemplate}>Download</button>
                  </div><Col id="templates">
                    <div class="text-grey">
                      <div>&nbsp;</div>
                      <div className="text-grey">Template</div>
                      <pre className="half-panel">
                        <code id="templateId">
                          {optimize ? prefixes.elements.map((item, id) => {
                            return <div key={id}>{item.template}</div>
                          }) :
                            sortedSubs.map((item, id) => {
                              return <div key={id}>{item.template}</div>
                            })
                          }
                        </code>
                      </pre>
                    </div>
                  </Col></Row>}
              <Row className="full-panel">
                {prefixes.errors.length > 0 && (
                  <Col id="errors">
                    <div>&nbsp;</div>
                    <div className="text-grey">Duplicate or errors ({prefixes.errors.length})</div>
                    <pre className="half-panel text-deny">
                      <code>
                        {prefixes.errors.map((prefix, id) => {
                          return <div key={id}>{prefix}</div>;
                        })}
                      </code>
                    </pre>
                  </Col>
                )}
    
                <Col id="prefixes">
                  <div>
                    <div className="text-grey">
                      Covered {prefixes.ipv6 ? '/64s' : 'IPs'}: {prefixes.prefixSize > 0 ? prefixes.prefixSize : null} ( {prefixes.prefixSize > 0 ? prefixes.aggMask : null} )
                    </div>
                    <div className="text-grey">
                      Prefixes ({prefixes.elements.length})
                    </div>
                    <pre className="half-panel">
                      <code>
                        {prefixes &&
                          prefixes.elements.map((item, id) => {
                            return <div key={id} className={item.tag === 'r' ? 'text-conflict' : null} >{item.prefix}
                              {item.coversTest ? (
                                <span className="text-success"> &lt;--Matches subnet</span>
                              ) : null}</div>;
                          })}
                      </code>
                    </pre>
                  </div>
                </Col>
                <Col id="sums">
                  <div className="text-grey">
                    <div>&nbsp;</div>
                    <div>Summaries ({prefixes.elements.length})</div>
                    <pre className="half-panel">
                      <code>
                        {prefixes &&
                          prefixes.summaries.map((summary, id) => {
                            return <div key={id}>{summary}</div>;
                          })}
                      </code>
                    </pre>
                  </div>
                </Col>
                <Col id="subs">
                  <div>
                    <div>&nbsp;</div>
                    <div className="text-grey">
                      Subnets ({prefixes.subnets.length} sorted{prefixes.sortsize ? " by size" : ""})
                    </div>
                    <pre className="half-panel">
                      <code>
                        {prefixes &&
                          prefixes.subnets.map((subnet, id) => {
                            return (
                              <div key={id}>
                                <span>{nSpaces(subnet.level)}</span>
                                {subnet.merged > 0 ? nMerged(subnet.merged) : null}
                                <span className={subnet.tag === '-' ? 'text-danger' : null}>{subnet.origin}</span>
                                {subnet.seq && <span className="text-muted"> (seq {subnet.seq})</span>}
                                {subnet.desc && <span className="text-muted"> {subnet.desc}</span>}
                              </div>
                            );
                          })}
                      </code>
                    </pre>
                  </div>
                </Col>
              </Row>
            </Container>
          }
        </>
      }
    </>
  );
};
