import { React, useState, useEffect } from 'react';
import { useFormik } from "formik";
import { Form, Button } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { TemplateConf } from "./template_form";

export const PrefixForm = (props) => {

  const [showTemplate, setShowTemplate] = useState(false);
  const [ multiline, setMultiline] = useState(false);
  const [ showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const clr = () => {
      formik.setFieldValue("nets", props.loadNow);
      formik.values.nets = props.loadNow;
      formik.submitForm();
      props.clrLoadNow();
    }
    if (props.loadNow.length > 0) {
      clr();
    }
  }, [props.loadNow]);

  let initVal = {
    nets: "",
    testnet: "",
    wildcard: false,
    sortsize: false,
    aggressive: true,
    template: "",
    optimize: props.optimize
  }

  const clearForm = () => {
    props.showDesc(true);
    formik.setFieldValue('testnet', "");
    setShowInfo(false);
    if (formik)
      formik.resetForm();
  };

  const renderTooltip = (props) => {
    return < Tooltip id="aggressivTip" {...props} >
      Aggressively combine partially overlapping prefixes with the 'le' value
    </Tooltip>
  }

  const handleForm = (e) => {
    formik.submitForm();
  }

  const [buttonText, setButtonText] = useState("Submit")

  const postValues = (values) => {
    if (!values.nets || typeof (values.nets) !== "string") return;
    let formIs = process.env.REACT_APP_HOST + "/api/v1/nets";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    if (requestOptions.body.length > 511000) {
      alert(`Character count ${requestOptions.body.length} exceeds limit`);
      return;

    }
    fetch(formIs, requestOptions).then(handleResponse);
    setButtonText("Working ");
  };

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values) => {
      postValues(values);
      updateAggressive();
    },
  });

  const onBlurText = (e) => {
    formik.handleBlur(e);
    if (e.nativeEvent.relatedTarget) {
      if (e.nativeEvent.relatedTarget.type==="submit")
        formik.submitForm();

    } 
    updateAggressive();

}
  const updateAggressive = () => {
    let textVal = formik.getFieldProps("nets").value;
    if (textVal?.includes("\n")) {
      setMultiline(true);
    } else {
      setMultiline(false);
    }
  }

  function handleResponse(resp) {
    setButtonText("Submit");
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
          alert("response: " + resp.status);
        }
        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
      }
      props.setPrefixes(data);
      props.showDesc(false);
      setShowInfo(true);
    });
  }

  return (
    <Form className="subform" onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="nets">
        <Form.Label>Prefixes, one per line</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows="6"
          placeholder="ipv4 or ipv6"
          value={formik.values.nets}
          onChange={formik.handleChange}
          onBlur={onBlurText}
          autoFocus={true}
          aria-describedby="prefixDialog"
        />
         <Form.Group controlId="testnet" className="col-4" >
         <Form.Label className="mt-3">Test for Subnet</Form.Label>
        <Form.Control
          type="text"
          style={{'border': '0px'}}
          label="Test for Subnet"
          value={formik.values.testnet}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        </Form.Group>
      </Form.Group>
    {multiline && (<>
      <Form.Group controlId="aggressive" className="d-flex align-items-top">
        <Form.Check
          type="switch"
          checked={formik.values.aggressive}
          value={formik.values.aggressive}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <OverlayTrigger overlay={renderTooltip} placement="top" delay={{ show: 250, hide: 400 }}>
          <Form.Label>Aggressive</Form.Label>
        </OverlayTrigger>
      </Form.Group>
      <Form.Group controlId="wildcard">
        <Form.Check
          type="switch"
          label="Summaries as wildcards (IPv4)"
          value={formik.values.wildcard}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        </Form.Group>
        <Form.Group controlId="sortsize">
        <Form.Check
          type="switch"
          label="Sort by Size"
          value={formik.values.sortsize}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Group>
      <TemplateConf formik={formik} optimize={props.optimize} setOptimize={props.setOptimize} />
    </>)}
      <Button className="blbutton" type="submit" onClick={handleForm}>
        {buttonText}{buttonText === 'Working ' ? (<div className="elipse">
          <span className="ladeda">...</span>
        </div>) : null}
      </Button>
      <Button className="clrbutton" onClick={clearForm}>Clear</Button>
      {showInfo?<div className="text-muted mt-3">Clear the form to view the tool's documentation</div>:null}
    </Form >
  )
}