import { Fragment } from 'react';
import { Col, Row } from "react-bootstrap";

export const Leaked = (props) => {
    let leaks = props.leaked;

    return <>
        <div className="full-panel">
        {leaks && Object.keys(leaks).map(ifname => {
            return <Row map={ifname}><Col className="colHeader fs-4">Interface: {ifname}</Col>
                {Object.keys(leaks[ifname]).map(r => {
                    return <Fragment key={r}>
                        <div><span className="code text-bold">Summary: {r}</span><span> ({leaks[ifname][r].length})</span></div>
                        {leaks[ifname][r].map((leak, i) => {
                            return <div key={i} className="padleft text-monospace"><span>{leak.dest} via {leak.iface}, {leak.age}</span></div>})
                        }
                        </Fragment>
                    })
                }
                </Row>
            })}
        </div>
    </>
}