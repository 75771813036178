import { useRef, useState } from 'react';
import { useWindowResize } from "./useWindowResize";
import { SummaryFolder } from "./summary_folder";

export const SummaryMap = (props) => {

    const netMapRef = useRef();
    const [showSums, setShowSums] = useState([]);
    const [selectedSub, setSelectedSub] = useState();
    const [selectedParent, setSelectedParent] = useState();
    const [childfolders, setChildFolders] = useState();
    const [drillIn, setDrillIn] = useState();
    let w = props.width;
    let c = w / 2;
    let selectedSpoke = useRef();
    useWindowResize(netMapRef);

    const clearSum = (e) => {
        if (e.target.id === "mapPanel") {
            selectedSpoke.current = -1;
            setShowSums([]);
            setSelectedSub(null);
            setSelectedParent(null);
            setDrillIn(null);
        }
    }

    const setSub = (val) => {
        setSelectedSub(val);
        if (val) {
            let ifce = sortedIf[selectedSpoke.current].iface;
            let kids = val.members;
            setDrillIn(kids);
        }
    }

    let sortedIf = []
    let routes = null;
    props.ifolder.map((f, i) => {
        let match = props.ifaces.filter((m) => m === f.iface);
        if (match.length === 1) {
            sortedIf.push({ ...f, i: sortedIf.length });
        }
    });

    const drillIface = (e) => {
        let iface = e.currentTarget.dataset.iface;
        setSub(null);
        selectedSpoke.current = Number(e.currentTarget.dataset.index);
        setShowSums(props.summaries.filter(s => {
            return s.nextHops.filter(n => {
                return n.ifaceName === iface;
            }).length > 0;
        }));
    }

    const sumdrilldown = (e) => {
        let sub = selectedSub;
        setSelectedParent(sub);
        setChildFolders(sub.members.filter(s => s.tag === 'f'));
    }

    const locOfNH = (nhStr) => {
        let ifIs = sortedIf.filter(path => path.iface === nhStr)[0]
        if (ifIs) {
            return spokes[ifIs.i];
        }
        return { x: 0, y: 0 };
    }

    if (netMapRef.current) {
        let box = netMapRef.current.getBoundingClientRect();
        w = box.width;
        c = w / 2;
    }

    const getIfCoord = () => {
        let angle = 2 * Math.PI / props.ifaces.length;
        return props.ifaces.map((face, i) => {
            return (-Math.PI / 2) + (angle * i);
        });
    }

    const getPoints = (r) => {
        return getIfCoord().map(angle => {
            return { x: r * Math.cos(angle), y: r * Math.sin(angle) }
        });
    }

    const spokes = getPoints(c - 200);

    let ifaceNames = props.summaries.map(s => {
        return s.nextHops.map(nh => {
            return nh.ifaceName;
        })

    })
    let sumoffset = 45 + 45 * (showSums.length - 1);

    let selectedIf = selectedSpoke.current && selectedSpoke.current > 0?sortedIf[selectedSpoke.current].iface:'none';

    return <div className="full-panel" ref={netMapRef}>
        <svg id="mapPanel" height={w} width={w} className="full-panel shiftleft" onClick={clearSum}>
            {(selectedSpoke.current === undefined || selectedSpoke.current === -1) && <svg>
                {spokes.map((p, i) =>
                    <svg onClick={drillIface} className="clickable" data-iface={sortedIf[i].iface} data-index={i} key={i}>
                        <line x1={c} y1={c} x2={c + p.x} y2={c + p.y} stroke="grey" />
                    </svg>
                )}
                <circle stroke="purple" fill="blue" cx={c} cy={c} r="30">What</circle>
                <line stroke="white" fill="blue" x1={c - 20} x2={c + 20} y1={c} y2={c}>What</line>
                <line stroke="white" fill="blue" x1={c} x2={c} y1={c + 20} y2={c - 20}>What</line>
                <polygon points={`${c - 25}, ${c} ${c - 15},${c - 5} ${c - 15},${c + 5}`} fill="white"></polygon>
                <polygon points={`${c + 25}, ${c} ${c + 15},${c - 5} ${c + 15},${c + 5}`} fill="white"></polygon>
                <polygon points={`${c}, ${c - 25} ${c - 5},${c - 15} ${c + 5},${c - 15}`} fill="white"></polygon>
                <polygon points={`${c}, ${c + 25} ${c - 5},${c + 15} ${c + 5},${c + 15}`} fill="white"></polygon>
            </svg>}
            {!selectedParent && showSums && showSums.map((sum, i) => {
                return <g key={i}>
                    {selectedSpoke.current != null && selectedSpoke.current > -1 && sum.nextHops.map(nh => {
                        return <line data-iface={nh.ifaceName} key={nh.ifaceName} x1={c} x2={locOfNH(nh.ifaceName).x + c} y1={c - sumoffset + 45 + (i * 90)} y2={locOfNH(nh.ifaceName).y + c} stroke="darkblue" strokeWidth={2} />
                    })}
                </g>
            })}
            {selectedParent && childfolders && childfolders.map((sum, i) => {
                return <g key={i}>
                    {selectedSpoke.current != null && selectedSpoke.current > -1 && sum.nextHops.map(nh => {
                        return <line key={nh.ifaceName + 'a'} x1={c} x2={locOfNH(nh.ifaceName).x + c} y1={c - sumoffset + 45 + (i * 90)} y2={locOfNH(nh.ifaceName).y + c} stroke="darkblue" strokeWidth={2} />
                    })}
                </g>
            })}
            {childfolders && selectedParent && childfolders.map((sum, i) => {
                return <g className="clickable" onClick={(e) => setSub(sum)} key={i}>
                    <rect x={c - 90} y={c - sumoffset + (i * 90)} width="180" height="65" rx="15" fill="rgb(242, 252, 255)" stroke="blue" />
                    <text x={c - 45} y={c - sumoffset + 40 + (i * 90)} fill="blue">{sum.prefix}</text>
                    <text x={c - 40} y={c - sumoffset + 60 + (i * 90)} fill="green">{sum.ipCounts[selectedIf]} ip</text>
                    {sum === selectedSub && sum.ipCounts[selectedIf] > 0 && <g className="hoverFill" onClick={sumdrilldown} >
                        <circle cx={c - 80} cy={c - sumoffset + 10 + (i * 90)} r={10} stroke="black"></circle>
                        <line x1={c - 90} x2={c - 70} y1={c - sumoffset + 10 + (i * 90)} y2={c - sumoffset + 10 + (i * 90)} stroke="black"></line>
                        <line x1={c - 80} x2={c - 80} y1={c - sumoffset + (i * 90)} y2={c - sumoffset + 20 + (i * 90)} stroke="black"></line>
                    </g>}
                </g>
            })}
            {showSums && !selectedParent && showSums.map((sum, i) => {
                return <g className="clickable" onClick={(e) => setSub(sum)} key={i}>
                    <rect x={c - 90} y={c - sumoffset + (i * 90)} width="210" height="65" rx="15" fill="rgb(242, 252, 255)" stroke="blue" />
                    <text x={c - 45} y={c - sumoffset + 40 + (i * 90)} fill="blue">{sum.prefix}</text>
                    <text x={c - 40} y={c - sumoffset + 60 + (i * 90)} fill="green">{sum.ipCounts[selectedIf]} ip</text>
                    {sum === selectedSub && <g className="hoverFill" onClick={sumdrilldown} >
                        <circle cx={c - 80} cy={c - sumoffset + 10 + (i * 90)} r={10} stroke="black"></circle>
                        <line x1={c - 90} x2={c - 70} y1={c - sumoffset + 10 + (i * 90)} y2={c - sumoffset + 10 + (i * 90)} stroke="black"></line>
                        <line x1={c - 80} x2={c - 80} y1={c - sumoffset + (i * 90)} y2={c - sumoffset + 20 + (i * 90)} stroke="black"></line>
                    </g>}
                </g>
            })}
            {spokes.map((p, i) =>
                <g onClick={drillIface} className="clickable" data-iface={sortedIf[i].iface} data-index={i} key={i}>
                    {i === selectedSpoke.current && <rect x={c + p.x - 95} y={c + p.y - 25} width="190" height="75" rx="20" fill="rgb(116, 153, 243)" />}
                    <rect x={c + p.x - 90} y={c + p.y - 20} width="180" height="65" rx="15" fill={sortedIf[i].default ? "rgb(233, 253, 255)" : "rgb(213, 223, 245)"} stroke="blue" r="5" />
                    <text x={c + p.x - 80} y={c + p.y} fill="blue">{sortedIf[i].iface}</text>

                    {selectedSub ? <text x={c + p.x - 60} y={c + p.y + 20} fill="blue">IP Count: {selectedSub.ipCounts[sortedIf[i].iface]}</text>
                        : <text x={c + p.x - 60} y={c + p.y + 20} fill="blue">{sortedIf[i].count} targets</text>}
                </g>
            )}
        </svg>
        {drillIn && drillIn.length > 0 &&
            <pre className="half-panel">
                {selectedSub && <code>{selectedSub.prefix} - {selectedIf}</code>}<br/>
                <code>{drillIn.map((s, i) =>
                    <SummaryFolder key={i} folder={s} recursion={props.recursion} selected={sortedIf[selectedSpoke.current]}/>)}
                </code>
            </pre>
        }
    </div>
}