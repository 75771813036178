import { React, useState } from "react";
import { useFormik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";

export const DifferenceForm = (props) => {
  let initVal = {
    listA: "",
    listB: ""
  };

  const clearForm = () => {
    props.showDesc(true);
    if (formik)
      formik.resetForm();
  };

  const [buttonText, setButtonText] = useState("Submit");

  const postValues = (values) => {
    if (!values.listA || !values.listB) return;
    let formIs = process.env.REACT_APP_HOST + "/api/v1/diffs";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    fetch(formIs, requestOptions).then(handleResponse);
    setButtonText("Working ");
  };

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values) => {
      postValues(values);
    },
  });

  function handleResponse(resp) {
    setButtonText("Submit");
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      props.showDesc(false);
      if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
          alert("response: " + resp.status);
        }
        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
      }
      props.setPrefixes(data);
    });
  }

  return (
    <Form className="subform" onSubmit={formik.handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="listA">
            <Form.Label>Subnet List A</Form.Label>
            <Form.Control
              style={{'backgroundColor': 'rgb(180 210 255)', 'border': '2px solid grey'}}
              type="text"
              as="textarea"
              rows="6"
              placeholder="ipv4 or IPv6 for list A"
              value={formik.values.listA}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3" controlId="listB">
            <Form.Label>Subnet List B</Form.Label>
            <Form.Control
              style={{'backgroundColor': 'rgb(180 245 245', 'border': '2px solid grey'}}
              type="text"
              as="textarea"
              rows="6"
              placeholder="ipv4 or IPv6 for list B"
              value={formik.values.listB}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
        </Col>
      </Row>
      <Button className="blbutton" type="submit">
        {buttonText}
        {buttonText === "Working " ?<div className="elipse">
                <span className="ladeda">...</span>
              </div> : null}
      </Button>
      <Button className="clrbutton" onClick={clearForm}>Clear</Button>
    </Form>
  );
};
