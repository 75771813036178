import { React } from "react";

export const PatternTopo = (props) => {
  let mapTopo = props.map[props.active];
  const stdby = (
    <svg>
      <text x="25" y="20" fill="rgb(100 102 224)" textLength="140px">
        Standby..
      </text>
    </svg>
  );
  const showToolTip = (e) => {
    let target = e.target.dataset.net;
    e.target.style.stroke = "white";
    let tlbx = document.getElementById("toolbox");
    tlbx.classList.remove("hidetip");
    tlbx.textContent = target;
  };

  const hideToolTip = (e) => {
    e.target.style.stroke = "grey";
    document.getElementById("toolbox").classList.add("hidetip");
  };
  let margin = 6;
  let r = 0;
  let invalid = false;
  if (!mapTopo || !mapTopo.topo) return stdby;
  let row_count = mapTopo.topo.length;
  let col_count = mapTopo.topo.reduce((pv, lvl) => {
    if (!lvl || !lvl.size) {
      invalid = true;
    } else if (lvl.size > pv) {
      return lvl.size;
    }
    return pv;
  }, 0);
  if (invalid) {
    return stdby;
  }
  if (30 / col_count < margin) {
    margin = 30 / col_count;
  }
  let grid = [];
  let conn = new Map();
  let info = new Map();
  if (col_count > row_count) {
    r = (100 - col_count * margin) / (2 * col_count);
  } else {
    r = (100 - row_count * margin) / (2 * row_count);
  }
  r = r < 3 ? 3 : r;
  r = r > 10 ? 10 : r;
  let row_space =
    (100 - 2 * r * row_count - 2 * margin) / (2 * (row_count - 1));
  mapTopo.topo.forEach((lvl, y) => {
    let row = [];
    let gap = (100 - lvl.size * 2 * r) / (lvl.size + 1);
    for (let x = 0; x < lvl.size; x++) {
      row.push([
        gap + r + x * (2 * r + gap),
        margin + r + y * 2 * (row_space + r),
        lvl.level.charAt(0) === "$" ? 1 : 0,
      ]);
    }
    grid.push(row);
    conn.set(lvl.level, row);
  });
  if (props.solution) {
    let patterns = props.solution.patterns;
    for (let pattern in patterns) {
      for (let mesh in patterns[pattern][0].meshes) {
        let levels = mesh.split("-");
        info.set(levels[0], patterns[pattern][0].meshes[mesh]);
      }
    }
  }
  return (
    <svg viewBox="0 0 100 100">
      <g fill="rgb(100 102 224)" strokeWidth={r / 12}>
        <rect
          width="100"
          height="100"
          className="topoPanel"
          rx={2}
          ry={2}
        ></rect>
        {mapTopo.connect.map((c) => {
          if (c && c.type && c.type === "p2p") {
            let p1 = conn.get(c.levels[0]);
            let p2 = conn.get(c.levels[1]);
            let tool = info.get(c.levels[0]);
            if (p1 && p2)
              return p1.map((pi, i) => {
                let dev = tool && tool.devices ? tool.devices[i] : null;
                return p2.map((pf, f) => {
                  let iface = null;
                  if (dev) iface = dev.ifaces[f];
                  return (
                    <line
                      key={String(pf[0]) + "-" + String(pf[1])}
                      x1={pi[0]}
                      x2={pf[0]}
                      y1={pi[1]}
                      y2={pf[1]}
                      stroke="grey"
                      strokeWidth=".5"
                      data-net={iface && iface.links[0] ? iface.links[0].subnet : "na"}
                      onMouseEnter={showToolTip}
                      onMouseLeave={hideToolTip}
                    ></line>
                  );
                });
              });
            else return null;
          }
          return null;
        })}
        {grid.map((lvl) => {
          return lvl.map((cell) => {
            return cell[2] === 0 ? (
              <circle
                key={cell[0] * 100 + cell[1]}
                cx={cell[0]}
                cy={cell[1]}
                r={r - 2}
                className="topoCircle"
              />
            ) : (
              <rect
                key={cell[0] * 100 + cell[1]}
                x={cell[0] - r}
                y={cell[1]}
                width={2 * r}
                height={r}
                className="topoCircle"
              />
            );
          });
        })}
        <text x={20} y={5} id="toolbox" className="linetip hidetip"></text>
      </g>
    </svg>
  );
};
