
export class PhyContainer {

    x = 0;
    dx = 0;
    y = 0;
    dy = 0;
    z = 0;
    x1 = 0;
    y1 = 0;
    i = 0;
    seq = 0;
    size = 0;
    height = 0;
    width = 0;
    linkedLeft;
    linkedRight;
    offset = {x:0, y:0};

    constructor(box, i, seq, size) {
        this.x = box.x;
        this.y = box.y;
        this.height = box.height;
        this.width = box.width;
        this.x1 = box.x + box.width;
        this.y1 = box.y + box.height;
        this.i = Number(i);
        this.seq = Number(seq);
        this.size = size;
        this.stacked = false;
        this.audio = new Audio(process.env.REACT_APP_HOST+"/wav/snap.wav");
    }

    isBonded = () => {
        return this.linkedLeft || this.linkedRight;
    }

    disconnect = () => {
        if (this.linkedLeft) {
            this.linkedLeft.bubbleLeft(-10, 0, 0);
            this.linkedLeft.linkedRight = null;
        }
        if (this.linkedRight) {
            this.linkedRight.bubbleRight(10, 0, 0);
            this.linkedRight.linkedLeft = null;
        }
        this.linkedLeft = null;
        this.linkedRight = null;
        this.stacked = false;
    }

    updateBoxPos = (box) => {
        this.dx = box.x - this.x;
        this.dy = box.y - this.y;
        this.x = box.x;
        this.y = box.y;
        this.setCorner();
        if (this.linkedLeft) {
            this.linkedLeft.bubbleLeft(this.dx, this.dy, this.z);
        }
        if (this.linkedRight) {
            this.linkedRight.bubbleRight(this.dx, this.dy, this.z);
        }
        return this;
    }

    containsPoint(x, y) {
        return (this.x <= x && this.x1 >= x && this.y <= y && this.y1 >= y);
    }

    getCenter = () => {
        let x = this.x + (this.width / 2);
        let y = this.y + (this.height / 2);
        return [x, y];
    }

    shiftXBy = (x) => {
        if (x > 0) {
            this.shirtRBy(x, 0);
        } else {
            this.shiftLBy(-x, 0);
        }
    }

    setOffset(offset) {
        this.offset = offset;
    };

    mouseMove(e) {
        if (this.offset) {
            this.updateBoxPos({'x':e.clientX + this.offset.x, 'y':e.clientY + this.offset.y});
        }
    }

    touches = (other) => {
        if (this.i === other.i)
            return false;
        let notTouch = (
            this.x >= other.x1 ||
            this.x1 <= other.x ||
            this.y >= other.y1 ||
            this.y1 <= other.y);
        if (notTouch && other.x >= this.x1 && this.linkedRight) {
            return this.linkedRight.touches(other);
        }
        if (notTouch && other.x1 <= this.x && this.linkedLeft) {
            return this.linkedLeft.touches(other);
        }
        return !notTouch;
    }

    snapRightOnX = (other) => {
        // if parts are snapped, their motion will be linked
        if (this.seq < 0 || other.seq < 0) {
            return false;
        }
        if (other.seq - 1 === this.seq && other.seq >= 0 && this.insideSnapWidth(other)) {
            let dx = this.x1 - other.x;
            let dy = this.y - other.y;
            this.linkedRight = other;
            other.linkedLeft = this;
            if (this.width < 130) {
                other.stacked = ~this.stacked;
            }
            other.bubbleRight(dx, dy, this.z);
            this.audio.play();
            return true;
        }
        if (this.linkedRight) {
            return this.linkedRight.snapRightOnX(other);
        }

    }

    insideSnapWidth = (other) => {
        return Math.abs(this.x - other.x1) < 20 || Math.abs(this.x1 - other.x) < 20;
    }

    snapLeftOnX = (other) => {
        // if parts are snapped, their motion will be linked
        if (this.seq < 0 || other.seq < 0) {
            return false;
        }
        if (other.seq + 1 === this.seq && this.seq >= 0 && this.insideSnapWidth(other)) {
            let dx = this.x - other.x1;
            let dy = this.y - other.y;
            this.linkedLeft = other;
            other.linkedRight = this;
            if (other.width < 130) {
                this.stacked = ~other.stacked;
            }
            other.bubbleLeft(dx, dy, this.z);
            this.audio.play();
            return true;
        }
        if (this.linkedLeft) {
         return this.linkedLeft.snapLeftOnX(other);
        }

    }

    bubbleRight(dx, dy, z) {
        this.reposition(this.x + dx, this.y + dy, z);
        if (this.linkedRight) {   
            this.linkedRight.bubbleRight(dx, dy, z);
        }
    }

    bubbleLeft(dx, dy, z) {
        this.reposition(this.x + dx, this.y + dy, z);
        if (this.linkedLeft) {
            this.linkedLeft.bubbleLeft(dx, dy, z);
        }
    }

    setZ(z) {
        this.z = z;
    }

    snapOnX = (other) => {
        let result = false;
        if (Math.abs(this.dx) > 2 || Math.abs(this.dy) > 1) {
            return false;
        }
        if (Math.abs(this.y - other.y) > 20) {
            return false;
        }
        if (other.seq > this.seq) {
            result = this.snapRightOnX(other);
        } else if (other.seq < this.seq) {
            result = this.snapLeftOnX(other);
        }
        return result;
    }

    hopCountLeft = () => {
        if (this.linkedLeft)
            return 1 + this.linkedLeft.hopCountLeft();
        return 1;
    }

    hopCountRight = () => {
        if (this.linkedRight)
            return 1 + this.linkedRight.hopCountRight();
        return 1;
    }

    hopCount = () => {
        // the center returns from both left and right.
        return this.hopCountLeft() + this.hopCountRight() - 1;
    }

    score = () => {
        return this.isBonded()?this.size:0;
    }

    reposition = (x, y, z) => {
        this.x = x;
        this.y = y;
        this.z = z
        this.setCorner();
    }

    setCorner = () => {
        this.x1 = this.x + this.width;
        this.y1 = this.y + this.height;
    }

    position = () => {
        return {
            'x': this.x,
            'y': this.y,
            'i': this.i,
            'cx': this.x1 - 24,
            'cy': this.y1 - 24,
        }
    }

    static getHalfCopy(model) {
        let h = model.height;
        let w = model.width;
        if (model.height === model.width) {
            h = h/2;
        } else {
            w = h;
        }
        let box = {"x": model.x, "y": model.y, "height": h, "width": w}
        return new PhyContainer(box, model.i, model.seq, model.size / 2)
    }

    doubleUp = () => {
        if (this.width === this.height) {
           this.width *= 2;
        } else {
            this.height = this.width;
        }
        this.size *= 2;
        this.setCorner();
    }
}

