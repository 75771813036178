import { useState } from "react";
import { Col, Container, Row, Table, FormCheck } from "react-bootstrap";
import { AllocateForm, GenSubnetForm } from "../components/forms/allocate_form";
import { useHeaderTags } from "../components/custom_hook";
import { SummaryFolder } from "../components/summary_folder";
import { HowMany4Nets, HowMany6Nets } from "../components/forms/subnet_form";

export const AllocateFlow = (props) => {
  const [prefixes, setPrefixes] = useState();
  const [showForm, setShowForm] = useState(true);
  const [selectForm, setSelectForm] = useState('none');
  const [desc, setShowDesc] = useState("true");

  useHeaderTags(props);

  const percentFrom = (total, remain) => {
    // returns a percent as a string, 2 decimal places
    let num = 100 * (1 - (remain / (total + remain))).toFixed(4)
    return num.toString().slice(0, 4);
  }

  const followMe = (e) => {
    let val = e.target.textContent;
    document.location.href = "/act/app/subnet/" + val.replace("/", "_");
  }

  const chooseForm = (e) => {
    let name = e.currentTarget.dataset.form;
    setSelectForm(name);
    switch (name) {
      case "hosts":
        e.currentTarget.querySelector("#hosts").checked = true;
        document.querySelector("#nets").checked = false;
        break;
      case "nets":
        e.currentTarget.querySelector("#nets").checked = true;
        document.querySelector("#hosts").checked = false;
        break;
    }
  }

  let wasted = 0;
  if (prefixes)
    wasted = prefixes.lostHost * prefixes.pieSlices.length;

  return (  
    <Container variant="fluid">
      <h5>Generator type</h5>
      <div className="subform">
        <div data-form="hosts" onClick={chooseForm} className={selectForm === 'hosts' ? "text-bold" : "clickable"}><FormCheck type="radio" active="true" id="hosts" label="Subnet space to cover a fixed number of hosts" /></div>
        <div data-form="nets" onClick={chooseForm} className={selectForm === 'nets' ? "text-bold" : "clickable"}><FormCheck type="radio" id="nets" label="Fixed number of subnets" /></div>
      </div>
    
      {showForm && (
        <><Row>{selectForm === 'hosts' && <Col><AllocateForm
          setPrefixes={setPrefixes}
          showForm={setShowForm}
          showDesc={setShowDesc}
        ></AllocateForm>
        </Col>}
          {selectForm === 'nets' && <Col><GenSubnetForm
            setPrefixes={setPrefixes}
            showForm={setShowForm}
            showDesc={setShowDesc}
          ></GenSubnetForm>
          </Col>}
        </Row>
        <Row><Col><HowMany4Nets/></Col><Col><HowMany6Nets/></Col></Row>
        </>
      )}
      {prefixes && !desc && (
        <Container>
          {prefixes.err && <Container><div className="err">{prefixes.errMsg}</div></Container>}
          {prefixes.message && <Container><div className="msg">{prefixes.message}.</div></Container>}
          <Row>
            <Col id="prefixes">
              <div>
                <div className="text-bold">
                  Prefixes: {prefixes.pieSlices.length} <span className="fw-normal"> ({prefixes.aggMask})</span>
                </div>
                <pre className="half-panel">
                  <code>
                    {prefixes &&
                      prefixes.pieSlices.map((prefix, id) => {
                        return prefix.template ? <div>{prefix.template}</div> : <div key={id}>{prefix.label}</div>;
                      })}
                  </code>
                </pre>
              </div>
            </Col>
            {prefixes.remainder >= 0 ? <Col id="prefixes">
              <div>
                <div className="text-bold">
                  Allocated: {prefixes.size + prefixes.remainder}, Remainder IPs: {prefixes.remainder},  Efficiency: {percentFrom(prefixes.size, wasted + prefixes.remainder)}
                </div>
                <pre className="half-panel">
                  <code>
                    {prefixes && <div>from summary {prefixes.parent.label} ({prefixes.parent.size + 1})</div>}
                    {prefixes &&
                      prefixes.pieSlices.map((prefix, id) => {
                        return <div key={id}>&nbsp;- {prefix.label} ({prefix.size + 1 - prefixes.lostHost})</div>;
                      })}
                  </code>
                </pre>
              </div>
            </Col> : <Col> <pre className="half-panel">
                  <code>
              {prefixes.nodes && prefixes.nodes.map((subnet, id) => {
                return (
                  <SummaryFolder key={id} folder={subnet} />
                );
              })}
            </code></pre></Col>}
          </Row>
        </Container>)}

      {(!prefixes || desc ) && (
      <Container>
      <div className="docs paper">
        <h3>Overview</h3>
        <p>
          Use this tool to create subnets.  You can create a fixed number of subnets or as many as needed to cover a fixed number of hosts from an address block you define. If you are creating subnets to cover a fixed
          number of hosts, then the address block must be large enough to cover all of the hosts. With this model, the tool will carve the block into smaller subnets based on defined mask parameters. The min and max subnet size controls
          how tightly subnets are created.  Set the min and max to the same value to have the same size blocks used.  Various sized subnets will be created within the CIDR parameters. Covering a fixed number of hosts applies specifically to IPv4.  For IPv6, best practice is to define a /64.  
          </p><p>
          If you are creating a fixed number of subnets, then the block defines the starting point.  The resulting subnets may extend beyond the block. In this case, all subnets will be the same size.  The planner or patterns tool can allocate subnets based on 
          other parameters.
        </p>
        <h3>Usage</h3>
        <p>
          The information that is needed by the tool differs slightly based on the generator type.
        </p>
        <p>
          When calculating host coverage, the tool needs size guidance to determine the correct number of subnets needs.  It can account for the fact that the network number and the broadcast address are not typically assigned to hosts. Select the <i>Account for unusable IPs</i> to take this into consideration.
        </p>
        <p>When calculating a fixed number of subnets, you can chose the mask size, or the number of hosts each subnet should cover. Unlike the host coverage option, each subnet will be the same size.</p>
        <p>You may also decide to carve up the anchor address block from the top of the available space first, then in descending order towards the bottom, lower subnet.  When working a fixed number of hosts, the tool will allocate subnets descending from the top of the block until the max subnet will
          cover all of the hosts, then it will allocate the smaller fractions in an ascending order so that smaller subnets do not fragment contiguous space lower in the block. 
          When working with a subnets, the allocated subnets will start from the stop of the anchor IP block and continue in descending order.  If more subnets are generated than the anchor block can hold, subnet allocation will 
          continue into the next adjacent anchor block.  If the anchor block is the same size as the generated subnets, then this will happen on the second assignment.
        </p>
        <p>
          A template can be used to embed the results into text, for example a configuration snippet. 
        </p>
        <p className="docs">
          <span className="fw-bold">Mask: </span>Display the output with dotted mask notation rather than CIDR notation<br />
          <span className="fw-bold">Template: </span>Display the output using a small configuration template.
        </p>
        <div className="docs">
          <span className="fw-bold">Using templates: </span>You can use the output to populate a template.  There are currently three supported substitutions:<br /><br />
          <Table variant="light">
            <tbody>
              <tr><td><span className="code font-monospace">&#36;&#123;subnet&#125;</span></td><td>Subnet value, for example 192.168.1.0 255.255.255.0</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;child&#125;</span></td><td>When subdividing, for example 192.168.1.64 255.255.255.192</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;host&#125;</span></td><td>First IP address on the subnet, with mask.  For example 192.168.1.1 255.255.255.0</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;index&#125;</span></td><td>Index number.  Useful for creating interfaces. For example, interace loopback&#36;&#123;index&#125;</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;--&#125;</span></td><td>Section break.  Useful for looping back through the subnets in a subsequent section.</td></tr>
            </tbody></Table>
        </div>
        <p>The following example shows a template used to create interfaces and route them in BGP. If you use this examaple, select 'Mask' to get the proper notation.</p>
        <pre className="tab templateEx">
          <code className="code">
          interface GigabitEthernet 0/&#36;&#123;index&#125;<br/>
          &nbsp;&nbsp;&#36;&#123;host&#125;
          <br/>
          &#36;&#123;--&#125;
          <br/>!<br/>
          router bgp 65535<br/>
          &#36;&#123;--&#125;<br/>
          &nbsp;&nbsp;network &#36;&#123;subnet&#125;<br/>
          &nbsp;&nbsp;neighbor &#36;&#123;host&#125; remote-as 6500&#36;&#123;index&#125;<br/>
          </code></pre>

      </div>
      </Container>)}
    </Container>
  );
};
