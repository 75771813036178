import { useState, forwardRef, useEffect } from 'react';
import { GamePartMenu } from './game_part_menu';

export const GamePart = forwardRef((props, ref) => {
    const pos = props.spot;
    if (!props.part) {
        console.log("part missing");
    }
    let width = props.part.width * 12;
    let height = props.part.height * 12;
    const [moreInfo, setMoreInfo] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    var edgeUp = false;

    useEffect(() => {
        setShowMenu(false);
    }, [props.clrMenu]);

    const startDrag = (e) => {
        if (e.target.nodeName === 'rect' && Number(e.currentTarget.dataset.obj) === props.spot.i) {
            props.liftPart(props.spot.i, { 'x': pos.x - e.clientX, 'y': pos.y - e.clientY });
        }
    }

    const endDrag = (e) => {
        props.dropPart();
    }

    const showMore = (e) => {
        edgeUp = true;
        setTimeout(() => {
            if(edgeUp)
                setMoreInfo(true)
        }, 250);
    }

    const showLess = (e) => {
        edgeUp = false;
        setMoreInfo(false)
    }

    const toggleMenu = (e) => {
        let prev = showMenu;
        endDrag(e);
        setShowMenu(~prev);
        props.liftPart(props.spot.i);
    }
    let bonded = props.spot.bonded;
    let stacked = props.spot.stacked;
    let menuIcon = process.env.REACT_APP_HOST + "/img/icons/hamburger.svg";
    let classIs = props.spot.bonded ? "part bonded" : "part"
    if (props.finish) {
        classIs = "part finish";
    }
    if (props.finish && props.part.seq < 0) {
        return null;
    }

    return (<>
        <g data-obj={props.spot.i} data-seq={props.spot.seq} className="clickable" onMouseDown={startDrag} onMouseUp={endDrag}>
            <rect ref={ref} className={classIs} x={pos.x} y={pos.y + 10} width={width} height={height}></rect>
            <text x={pos.x + 5} y={bonded && stacked ? pos.y - 20 : pos.y} fill="black">{props.part.label}</text>
            {moreInfo && <text x={pos.x + 5} y={pos.y + 27} fill="yellow" fontSize="0.65em">{props.part.dotMask}</text>}
            {typeof (pos.cx) === 'number' && !props.finish && <svg x={pos.cx} y={pos.cy + 10} width="20" height="20" onMouseEnter={showMore} onMouseLeave={showLess} onClick={toggleMenu}>
                <image x={0} y={0} width="20" height="20" href={menuIcon}></image>
            </svg>}
            {moreInfo && props.part.size > 32 && <text fill="yellow" x={pos.x + 5} y={pos.y + 47}>SIZE: {props.part.size}</text>}
            {showMenu && <GamePartMenu {...props.spot} label={props.part.label} menu={props.menu} closeMenu={toggleMenu}></GamePartMenu>}
        </g>

    </>
    );
});

