import { useState, useEffect, useRef } from 'react';

export const useWindowResize = (canvas) => {
    let inThrottle = useRef(false);
    const [windowSize, setWindowSize] = useState({
        x: window.innerWidth,
        y: window.innerHeight,
    });
    if (canvas.current) {
        let x = canvas.current.getBoundingClientRect().width;
        canvas.current.firstChild.style.width = String(x)+"px";
        
    }
        
    useEffect(() => {
        function handleResize() {
            if (inThrottle.current === null || inThrottle.current === true) {
                return;
            }
            inThrottle.current = true;
            setTimeout(() => {
                inThrottle.current = false;
                setWindowSize({
                    x: window.innerWidth,
                    y: window.innerHeight,
                });
            }, 150);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

}