import { React, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { DifferenceForm } from '../components/forms/difference_form';
import { useHeaderTags } from  "../components/custom_hook";
import { SubnetItem } from '../components/subnet_item';

export const DifferenceFlow = (props) => {

  const [prefixes, setPrefixes] = useState();
  const [showDesc, setShowDesc] = useState(true);

  useHeaderTags(props);

  const nSpaces = (n) => {
    if (n === 0) {
        return;
    }
    return (
        "  ".repeat(n) + "∟-"
    );
};

const nMerged = (n) => {
    if (n === 0) {
        return;
    }
    if (n === 1) {
        return " \\";
    }
    return " /";
};

  const textColor = (c) => {
    return c === "c" ? "text-blue" : "text-green";
  }

  const textLColor = (c) => {
    return c === "c" ? "text-lblue" : "text-lgreen";
  }

  return (
    <>
      <Container variant='fluid'>
        <DifferenceForm setPrefixes={setPrefixes} showDesc={setShowDesc} ></DifferenceForm></Container>
      {showDesc ? (
        <Container>
          <Col className="docs paper">
            <h3>Description</h3>
            <p>Compare two sets of subnets. Because subnets may be embedded in other text such as object-groups or routes, you can use this tool to compare a prefix-list against subnets adverstised by a BGP neighbor, or compare two small route tables.</p>
            <p>The tool will consider overlaps or conflicts. For example, if list A has a subnet 10.20.0.0/22 and list B has a subnet 10.20.1.0/24, then list A covers everyhing in list B. But list B is missing a /23 and a /24 that is covered only by list A.  This
              type of partial coverage may indicate some issue with the policy and is detailed as a conflict.  If both lists completely cover 10.20.0.0/22, even if list A presents it in mask notation, 10.20.0.0 255.255.252.0, then that Subnet
              is shown as common between both lists. With conflicts, the &quot;missing column will lists the subnets required to resolve the conflict.
            </p>
            <h3>Usage</h3>
            <p>List A is Blue, List B is green.  The output consists of 6 groups.  <li>Subnets exclusive to list A appear only in that list and are not covered in List B, and vise-versa. </li>
              <li>Subnets common to both list are the exact overlap of the address space.</li>
              <li>Subnets in conflict mean that the subnet in one list partially covers the subnet of the other list.</li>
              <li>Missing in A or B are the subnets required to resolve a conflict. Or in otherwords, the
                subnets in one list that are not present in the other.  For IPv6, conflicts are only resolved to a /64 mask to keep the results manageable.</li> </p>
            <h3>Examples</h3>
            <p>Here are some examples you can try. Copy and paste directly into the form above. These are shown moslty sorted, but that is not a requirement. Unsorted lists will be sorted as part of the analisys:</p>
            <div>List A<br />
              <div className="tab"><code>
                permit 192.168.0.0 0.0.0.255<br />
                permit 192.168.1.0 0.0.0.255<br />
                permit 192.168.2.0 0.0.1.255<br />
                permit 192.168.2.0 0.0.0.255<br />
                permit 192.168.4.0 0.0.0.255<br />
                permit 192.168.5.0 0.0.0.255<br />
                permit 192.168.6.0 0.0.0.255<br />
                permit 192.168.9.0 0.0.0.255<br />
                permit 192.168.10.0 0.0.1.255<br />
                10.0.0.0/8
              </code></div>
            </div><br/>
            <div>List B<br />
              <div className="tab"><code>
                ip route 192.168.0.0 255.255.252.0<br />
                ip route 192.168.4.0 255.255.255.0<br />
                ip route 192.168.6.0 255.255.254.0<br />
                ip route 192.168.8.0 255.255.255.0<br />
                what about 192.168.10.0/24?<br />
                host 192.168.2.1/32

              </code></div>
            </div>
          </Col>
        </Container>
      ) : (<Container>
        <Row className="docs paper">
          <Col>
            <div className="text-dark">Exclusive to A</div>
            <pre className="half-panel text-blue height-limit">
              <code>
                {prefixes &&
                  prefixes.listA.map((p, id) => {
                    return <SubnetItem id={id} subnet={p} />
                  })}
              </code>
            </pre>
          </Col>
          <Col>
            <div className="text-dark">Common</div>
            <pre className="half-panel text-green height-limit">
              <code>
                {prefixes &&
                  prefixes.common.map((p, id) => {
                    return <div key={id}>
                      <span>{p.merged > 0 ? null : nSpaces(p.level)}</span>
                      <span className="text-blue">{p.root}</span>
                      <span className="text-grey">&nbsp;&lt;--&gt;&nbsp;</span>
                      <span className="text-green">{p.root}</span>{p.merged > 0 ? nMerged(p.merged) : null}
                      {p.desc && <span className={textLColor(p.tag)}> - {p.desc}</span>}
                      </div>;
                  })}
              </code>
            </pre>
          </Col>
          <Col>
            <div className="text-dark">Exclusive to B</div>
            <pre className="half-panel text-green height-limit">
              <code>
                {prefixes &&
                  prefixes.listB.map((p, id) => {
                    return <SubnetItem subnet={p} id={id} />
                  })}
              </code>
            </pre>
          </Col>
        </Row>
        <Row className="docs paper">
          <Col>
            <div className="text-dark">Not Covered by A</div>
            <pre className="half-panel text-green height-limit">
              <code>
                {prefixes &&
                  prefixes.missingA.map((p, id) => {
                    return (<div key={id}>{p}</div>);
                  })}
              </code>
            </pre></Col>
          <Col>
            <div className="text-dark">Conflicts</div>
            <pre className="half-panel text-green height-limit">
              <code>
                {prefixes &&
                  prefixes.conflicts.map((p, id) => {
                    return (<div key={id} className={textColor(p.tag)}><span>{nSpaces(p.level)}</span>{p.dotNotation}
                    {p.desc && <span className={textLColor(p.tag)}> - {p.desc}</span>}
                    </div>);
                  })}
              </code>
            </pre></Col>

          <Col><div className="text-dark">Not covered by B</div>
            <pre className="half-panel text-blue height-limit">
              <code>
                {prefixes &&
                  prefixes.missingB.map((p, id) => {
                    return (<div key={id}>{p}</div>);
                  })}
              </code>
            </pre></Col>
        </Row>
        <p>Note: 'Not Covered' are the result of conflict resolution. The smaller subnet is subtracted from the larger subnet for each conflict.  The result contains subnets that were not part of the submitted lists.</p>
      </Container>)}
    </>
  )
}