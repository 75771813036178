import { useState, useRef, useEffect } from 'react'

/*
draws rectangle for this subnet
props:
    folder: list of subnets (same used by summary_folder)
    root:  if true, tape drawn at 1000 pt, children scaled accordingly
    expand:  if true, children are drawn on top
    y_list:  list of all y positions realative to svg canvas
    y_index:  index pointer into list
    box: parent box dimensions {x, w} (scaled by folder percent and offset)
*/

export const SummaryTape = (props) => {

    if (props.folder === undefined) {
        return null;
    }
    let focus = false;
    let width = props.box.w * props.folder.siblingPercent;
    let x = props.box.x + (props.folder.siblingPosition * props.box.w);
    let y = 'y' in props?props.y:props.folder.y;
    let height = 20;
    let incMembers = props.folder.hasOwnProperty("expand")?props.folder.expand:true;
    let visible = props.hasOwnProperty("visible")?props.visible:props.root;
    let recursion = 'recursion' in props?props.recursion:incMembers;
    let fillColor = "rgba(0,0,0,.2)";
    let overlay = props.hasOwnProperty("overlay")?props.overlay:false;
    let circleColor = "white";
    if (y === undefined)
        y = 0;

    // can we through this guy and his children out?
    if (props.parents && props.parents.parent) {
        if (!(props.parents.parent.includes(props.folder.prefix)) && !visible)
            return null;
        if (props.parents.parent[0] === props.folder.prefix) {
            focus = true;
            visible = true;

        } 
    } else {
        visible = true;
    }  
    if (!('y' in props)) {
        switch (props.folder.tag) {
            case 'f':
                fillColor = "lightgrey";
                break;
            case '.':
                fillColor = "rgb(116 153 243)";
                break;
            case 'x':
                fillColor = "lightgreen";
        }
    }
    if (props.root || (focus && visible && props.winWidth && props.hasOwnProperty('y') === false)) {
        width = props.winWidth;
        visible = true;
        circleColor = "lightgreen";
        x = 0
    }

    const setNewRoot = (e) => {
        let target = e.currentTarget;
        if (target.getAttribute("fill") === "lightgreen") {
            props.parents.setFocusNode(null);
        } else {
              // current focus at index 0
            props.parents.setFocusNode(e.currentTarget.id);
        }
    }
    // console.log(props.folder.prefix + " in x:" + props.box.x + " w: "+ props.box.w + " y:" + y + " propY: "+ props.y+ " Overlay:" + props.overlay);

    return <>
        {visible?
            <rect data-pre={props.folder.prefix} x={x+height} width={width + 0.5} y={y} height={height} fill={fillColor} stroke={props.folder.tag==='f'?"darkgray":"rgb(16 53 143)"}></rect>:null}
        {y > 0 && props.folder.members && props.root === false && props.overlay === false?
            <circle className="clickable" onClick={setNewRoot} id={props.folder.prefix} r={height/2} cx={height/2} cy ={y+height/2} stroke="black" fill={circleColor}/>:null}
        {overlay === false && props.folder.members && recursion && incMembers && width > 0 && props.folder.members.map((m, i) => {
            return <>
                <SummaryTape overlay={false} parents={props.parents} visible={focus || visible} p={props.folder.prefix} winWidth={props.winWidth} key={i} box={{x: x, w: width}} folder={m} root={false}/>
            </>
            })
        }
        {props.folder.members && width > 2 && props.folder.members.map((m, i) => {
            return <>
                <SummaryTape overlay={true} parents={props.parents} visible={focus || visible} p={props.folder.prefix} key={i} y={y} box={{x: x, w: width}} folder={m} root={false} recursion={recursion}/>
            </>
            })
        }
    </>

}