import { strIp4ToInt, strIp6ToInt } from "./iptools";

export class PrefixClasz {
    constructor(node) {
        this.subnet = node.prefix;
        if (this.is4()) {
            this.s = strIp4ToInt(this.subnet);
            this.w = BigInt(Math.pow(2, (32 - node.prefix.split("/")[1]))-1);
        } else {
            this.s = strIp6ToInt(this.subnet);
            this.w = BigInt(Math.pow(2, (128 - node.prefix.split("/")[1]))-1);
        }

        this.members = [];
        if (node.members) {
            node.members.forEach(m => {
                this.members.push(new PrefixClasz(m))
            })
        }

    }

    findPath4 = (node, inventory) => {
        let sub =  strIp4ToInt(node);
        let end = sub + BigInt(Math.pow(2, (32 - node.split("/")[1]))-1);
        if (this.covers(sub, end)) {
            inventory.push(this.subnet);
            this.members.forEach(m => {
                return m.findPath4(node, inventory);
            });
        };
        return inventory;   
    }

    findPath6 = (node, inventory) => {
        let sub =  strIp6ToInt(node);
        let end = sub + BigInt(Math.pow(2, (128 - node.split("/")[1]))-1);
        if (this.covers(sub, end)) {
            inventory.push(this.subnet);
            this.members.forEach(m => {
                return m.findPath6(node, inventory);
            });
        };     
    }

    covers(a, b) {
        return this.s <= a && this.s + this.w >= b;
    }

    is4 = () => {
        return this.subnet.indexOf('.') > 0
    }

}