import { React, useState } from "react";
import { Form, Button, Row, Col, Table } from "react-bootstrap";
import { useFormik } from "formik";

const AllocateForm = (props) => {

  const [showTemplate, setShowTemplate] = useState(false);

  const toggleTemplate = () => {
    if (showTemplate) {
      setShowTemplate(false);
      formik.setFieldValue('template', "");
      formik.submitForm();
    } else {
      setShowTemplate(true);
    }
  }

  const clearForm = () => {
    props.showDesc(true);
    if (formik)
      formik.resetForm();
  };

  let initVal = {
    anchorNet: "10.0.0.0/8",
    size: "1500",
    considerLostHost: false,
    minMask: 28,
    maxMask: 24,
    descending: false,
    longLabel: false,
    template: ""
  };

  const updateWithChange = (e) => {
    formik.handleChange(e);
    formik.submitForm(e);
  };

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values) => {
      postValues(values);
    },
  });

  const postValues = (values) => {
    let formIs = process.env.REACT_APP_HOST + "/api/v1/allocate";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    fetch(formIs, requestOptions).then(handleResponse);
  };

  function handleResponse(resp) {
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
          alert("response: " + resp.status);
        }

        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
      }
      props.showDesc(false);
      props.setPrefixes(data);
    });
  }

  return (
    <Form className="subform" onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="anchorNet">
        <Form.Label>Anchor IPv4 Block</Form.Label>
        <Form.Control
          type="text"
          placeholder="ipv4 only"
          value={formik.values.anchorNet}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoFocus={true}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="size">
        <Form.Label>IPs required</Form.Label>
        <Form.Control
          type="number"
          placeholder="256"
          value={formik.values.size}
          onChange={updateWithChange}
          onBlur={formik.handleBlur}
          autoFocus={true}
        />
      </Form.Group>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="maxMask">
            <Form.Label>Largest Subnet, mask</Form.Label>
            <Form.Control
              type="number"
              placeholder="1"
              value={formik.values.maxMask}
              onChange={updateWithChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="minMask">
            <Form.Label>Smallest Subnet, mask</Form.Label>
            <Form.Control
              type="number"
              placeholder="32"
              value={formik.values.minMask}
              onChange={updateWithChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="considerLostHost">
            <Form.Label>Account for unuseable IPs</Form.Label>
            <Form.Check
              type="checkbox"
              value={formik.values.considerLostHost}
              onChange={updateWithChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="descending">
            <Form.Label>Descending order</Form.Label>
            <Form.Check
              type="checkbox"
              value={formik.values.descending}
              onChange={updateWithChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
        </Col>
        <hr />
        <Row>
          <Col>
            <Form.Group controlId="longLabel" className="mb-3">
              <Form.Check
                type="switch"
                label="Mask"
                value={formik.values.longLabel}
                onChange={updateWithChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type="switch"
                label="use template"
                onChange={toggleTemplate}
              />
            </Form.Group>
          </Col>
        </Row>
      </Row>
      {showTemplate &&
        <Row>
          <Col>
            <Form.Group controlId="template" className="mb-3">
              <Form.Label>Template</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={`$\{index}: index\n$\{subnet}: Subnet\n$\{host}: first IP`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.template}
              />
            </Form.Group>
          </Col>
        </Row>
      }

      <Button className="blbutton" type="submit">
        Allocate
      </Button>
      <Button className="clrbutton" onClick={clearForm}>Clear</Button>
    </Form>
  );
};

const GenSubnetForm = (props) => {

  const [showTemplate, setShowTemplate] = useState(false);
  const [ buttonText, setButtonText ] = useState("Submit")
  const [ eachSize, setEachSize ] = useState(256);

  const toggleTemplate = () => {
    if (showTemplate) {
      setShowTemplate(false);
      formik.setFieldValue('template', "");
    } else {
      setShowTemplate(true);
    }
  }

  const clearForm = () => {
    props.showDesc(true);
    if (formik) {
      formik.resetForm();
      setEachSize(256);
    }
  };

  const bestFit = (e) => {
    let optimize = e.target.checked;
   
    if (optimize === true) {
       optimizer();
    }
  }

  function optimizer() {
    let maskVal = Number(formik.values.anchorNet.split("/")[1].trim());
    let isIPv6 = formik.values.anchorNet.lastIndexOf(':') > 0;
    let count = Number(formik.values.size);
    if (count < 1) {
      return;
    }
    if (isIPv6 == false) {
      let m = 2**(32-maskVal)/count;
      let covrs = 1;
      let msk = 32;
      while (covrs <= m && msk > 0) {
        covrs = covrs << 1;
        msk -= 1;
      }
      setEachSize(covrs >> 1);
      formik.setFieldValue("mask", msk + 1);
      setSubSelectors(msk + 1);
    } else {
      let m = Math.log2(count);
      let m_val = maskVal + Math.floor(m - 0.000001) + 1
      formik.setFieldValue("mask", m_val);
      setSubSelectors(m_val);
    }
     
  }

  const updateSize = (e) => {
    let val = e.target.value;
    setEachSize(val);
    let covrs = 1;
    let msk = 32;
    while (covrs <= val && msk > 0) {
      covrs = covrs << 1;
      msk -= 1;
    }
    document.getElementById("bestfit").checked = false;
    formik.setFieldValue("mask", msk);
    setSubSelectors(msk);
  }

  const handleSizeChange = (e) => {
    if (document.getElementById("bestfit").checked === true) {
      optimizer();
    }
    formik.handleChange(e);
  }

  const handleBlockChange = (e) => {
    document.getElementById("bestfit").checked = false;
    let mask = e.currentTarget.value;
    let maskVal = Number(mask.split("/")[1]);
    if (maskVal > 0 && maskVal > formik.values.mask) {
       formik.setFieldValue("mask", maskVal + 1);
    } 
    formik.handleChange(e);
  }

  const checkv6 = (e) => {
    let val = e.target.value;
    if (val.indexOf(":") >= 0 && !formik.touched.minMask) {
      formik.setFieldValue("mask", 64);
      setSubSelectors(64);
    }
    formik.handleChange(e)
  };


  let initVal = {
    anchorNet: "10.0.0.0/8",
    size: "10",
    mask: "24",
    descending: false,
    longLabel: false,
    template: "",
    submask: ""
  };

  const updateMask = (e) => {
    let blockMask = formik.values.anchorNet;
    let blockVal = Number(blockMask.split("/")[1]);
    let val = e.target.value;
    if (val < blockVal) {
      val = blockVal;
    }
    setEachSize(2 ** (32 - val));
    formik.setFieldValue("mask", val);
    document.getElementById("bestfit").checked = false;
    setSubSelectors(val)
  }

  const setSubSelectors = (fromStr) => {
    let from = Number(fromStr);
    let elem = document.getElementById("submask");
    while (elem.childElementCount > 0) {
      elem.remove(0);
    }
    elem.add(new Option("Not sub divided", "-1"))
    if (formik.values.anchorNet.lastIndexOf(':') < 0) {
        for (let element = from + 1, pow = 2; element < from + 10 && element < 31; element++, pow *= 2) {
          let size = 1 << (32 - element);
          let desc = `/${element} (${pow} x ${size})`;
          elem.add(new Option(desc, element));
        }
      } else {
        for (let element = from + 1, pow = 2; element < from + 10 && element < 127; element++, pow *= 2) {
          let size = 1 << (64 - element);
          let desc = `/${element} (${pow} x ${size} /64's)`;
          if (element > 64) {
            desc = `/${element}`
          }
          elem.add(new Option(desc, element));
        }
      }
  }

  const updateWithChange = (e) => {
    formik.handleChange(e);
  };

  const checksubmask = (e) => {
    let mask = Number(formik.values.mask);
    let submask = Number(formik.values.submask);
    if (submask > 0 && submask < mask) {
      formik.setFieldValue("submask", mask);
    }
    
  }

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values) => {
      postValues(values);
    },
  });

  const postValues = (values) => {
    let formIs = process.env.REACT_APP_HOST + "/api/v1/allocate";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    fetch(formIs, requestOptions).then(handleResponse);
    setButtonText("Working ");
  };

  function handleResponse(resp) {
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
          alert("response: " + resp.status);
        }

        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
      }
      props.showDesc(false);
      props.setPrefixes(data);
      setButtonText("Submit");
    });
  }

  return (
    <Form className="subform" onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="anchorNet">
        <Form.Label>Starting From</Form.Label>
        <Form.Control
          type="text"
          placeholder="ipv4 or ipv6"
          value={formik.values.anchorNet}
          onChange={handleBlockChange}
          onBlur={checkv6}
          autoFocus={true}
        />
      </Form.Group>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="size">
            <Form.Label>Subnets required</Form.Label>
            <Form.Control
              type="number"
              placeholder="256"
              value={formik.values.size}
              onChange={handleSizeChange}
              onBlur={handleSizeChange}
              autoFocus={true}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="mask">
            <Form.Label>Subnet mask</Form.Label><span><input id="bestfit" onChange={bestFit} style={{"marginLeft": "20px"}} type="checkbox"></input>&nbsp;Best Fit</span>
            <Form.Control
              type="number"
              placeholder="24"
              value={formik.values.mask}
              onChange={formik.handleChange}
              onBlur={updateMask}
              autoFocus={true}
            />
          </Form.Group></Col>
          {formik.values.anchorNet.includes(".") ?<Col>
          <Form.Group className="mb-3">
            <Form.Label>Size each</Form.Label>
            <Form.Control
              type="number"
              placeholder="256"
              value={eachSize}
              onChange={updateSize}
              onBlur={formik.handleBlur}
            />
          </Form.Group></Col>:<Col>&nbsp;</Col>}
      </Row>
      <hr/>
      <Row><Col></Col>
        <Col>
        <Form.Group className="mb-3" controlId="submask" value={formik.values.submask}>
    <Form.Label>Optionally Sub divide with a mask</Form.Label>
      <Form.Select
        id="submask"
        onChange={formik.handleChange}
        onBlur={checksubmask}>
        </Form.Select>
       </Form.Group>
       </Col><Col></Col>
      </Row>
      <hr/>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="descending">
            <Form.Label>Descending order</Form.Label>
            <Form.Check
              type="checkbox"
              value={formik.values.descending}
              onChange={updateWithChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
        </Col>
        <hr />
        <Row>
          <Col>
            <Form.Group controlId="longLabel" className="mb-3">
              <Form.Check
                type="switch"
                label="Mask"
                value={formik.values.longLabel}
                onChange={updateWithChange}
                onBlur={formik.handleBlur}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Check
                type="switch"
                label="use template"
                onChange={toggleTemplate}
              />
            </Form.Group>
          </Col>
        </Row>
      </Row>
      {showTemplate &&
        <Row>
          <Col>
            <Form.Group controlId="template" className="mb-3">
              <Form.Label>Template</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={`$\{index}: index\n$\{subnet}: Subnet\n$\{host}: first IP`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.template}
              />
            </Form.Group>
          </Col>
          <Col>
          <Table size="sm">
            <tbody>
              <tr><td><span className="code font-monospace">&#36;&#123;subnet&#125;</span></td><td>Subnet value</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;child&#125;</span></td><td>When sub dividing, first 6 and last 6 children</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;host&#125;</span></td><td>First IP address on the subnet</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;index&#125;</span></td><td>Index number</td></tr>
              <tr><td><span className="code font-monospace">&#36;&#123;--&#125;</span></td><td>Section break</td></tr>
            </tbody></Table>
          </Col>
        </Row>
      }
      <Button className="blbutton" type="submit">
        {buttonText}{buttonText === 'Working ' ? (<div className="elipse">
          <span className="ladeda">...</span>
        </div>) : null}
      </Button>
      <Button className="clrbutton" onClick={clearForm}>Clear</Button>
    </Form>
  );
};

export { AllocateForm, GenSubnetForm }
