import { React, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form, Button } from "react-bootstrap";

export const SubnetForm = (props) => {

  const [bbinary, setBbinary] = useState(Number(props.bbVal).toString(2));
  const [errMessage, setErrMessage] = useState();

  useEffect(() => {
    if (props.center) {
      let getFor = { ...initVal, "label": props.center }
      postValues(getFor);
      formik.values.label = props.center;
    }
  }, [props.center]);

  let x = parseInt((document.documentElement.clientWidth - 400) / 150);
  let y = parseInt((document.documentElement.clientHeight - 200) / 200);

  x = x < 1 ? 1 : x;
  y = y < 1 ? 1 : y;

  let initVal = {
    label: props.center,
    parentSize: y,
    siblingSize: x,
    generationSize: x + 1,
    parent: props.parent,
    bbVal: props.bbVal
  }
  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values) => {
      postValues(values);
    },
  });

  const updateBits = (e) => {
    { formik.handleChange(e) }
    setBbinary(Number(e.target.value).toString(2));
  }

  const clearForm = () => {
    props.showDesc(true);
    if (formik)
      formik.resetForm();
  };

  const postValues = (values) => {
    if (!values.label || typeof (values.label) !== "string") return;
    let formIs = process.env.REACT_APP_HOST + "/api/v1/map";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values)
    };
    fetch(formIs, requestOptions).then(handleResponse);
  };

  function handleResponse(resp) {
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
          alert("response: " + resp.status);
        }

        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
      }
      props.showDesc(false);
      props.setSub(data);
      if (data.err !== true)
        formik.setFieldValue("label", data.element.label);
      if (data.element.note) {
        setErrMessage(data.element.note);
      } else {
        setErrMessage(null);
      }
      if (data.element.ipv6) {
        props.setClassful(false);
      }
    });
  }

  const toggleClass = () => {
    let oldVal = props.classful;
    formik.submitForm();
    if (props.ipv6) {
      props.setClassful(false)
    } else {
      props.setClassful(~oldVal);
      if (oldVal === -1) {
        formik.setFieldValue('bbVal', '-1');
      } else {
        formik.setFieldValue('bbVal', props.bbVal);
        setBbinary(Number(props.bbVal).toString(2));
      }
    }
  }

  return (
    <Form className="subform" onSubmit={formik.handleSubmit}>
      <Form.Group className="mb-3" controlId="label">
        <Form.Label>Subnet</Form.Label>
        <Form.Control
          type="text"
          placeholder="ipv4 or ipv6"
          value={formik.values.label}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoFocus={true}
        />
      </Form.Group>
      <Form.Check label="Classful" disabled={props.ipv6} checked={props.classful} onChange={toggleClass}>
      </Form.Check>
      {props.classful && props.allowBits ? <div style={{ "margin-left": "35px" }}>
        <Form.Group className="mb-3" controlId="bbVal">
          {errMessage ? <Form.Label>{errMessage}</Form.Label> : <Form.Label>Borrowed Bits Value, subnet number {Number(formik.values.bbVal) + 1} under the network class)</Form.Label>}
          <Form.Control style={{ "width": "25%" }}
            type="text"
            value={formik.values.bbVal}
            onChange={updateBits}
            onBlur={formik.handleBlur}>
          </Form.Control><div style={{ "margin-left": "10px" }}>Binary: {bbinary}</div>
        </Form.Group>
      </div> : null}
      <Form.Group>
        <Button className="blbutton" type="submit">
          Chart
        </Button>
        <Button className="clrbutton" onClick={clearForm}>Clear</Button>
      </Form.Group>
    </Form>
  );
};


export const HowMany4Nets = () => {

  useEffect(() => {
    let sel1 = document.getElementById("parentMask4");
    let sel2 = document.getElementById("childMask4");
    if (sel1 !== null && sel2 !== null) {
      sel1.add(new Option("--", 0));
      sel2.add(new Option("--", 0));
      for (let i = 32; i > 0; i--) {
        sel1.add(new Option(`/${33 - i}`, 33 - i));
      }
    }
  }, []);

  const updateAns = (e) => {
    let input1 = e.currentTarget;
    let inVal = input1.value;
    if (input1.id === "parentMask4") {
      let sel2 = document.getElementById("childMask4");
      while (sel2.childElementCount > 0) {
        sel2.remove(0);
      }
      for (let i = Number(inVal) + 1; i < 31; i++) {
        sel2.add(new Option(`(${2 ** (i - inVal)}) /${i}`, i));
      }
    }
  }

  return <Form className="subform">
    An IPv4 block with a mask of
    <select id="parentMask4" className="selects" onChange={updateAns}></select>
    contains
    <select id="childMask4" className="selects"></select> subnets
  </Form>
}

export const HowMany6Nets = () => {

  useEffect(() => {
    let sel1 = document.getElementById("parentMask6");
    let sel2 = document.getElementById("childMask6");
    if (sel1 !== null && sel2 !== null) {
      sel1.add(new Option("--", 0));
      sel2.add(new Option("--", 0));
      for (let i = 64; i > 32; i--) {
        sel1.add(new Option(`/${96 - i}`, 96 - i));
      }
    }
  }, []);

  const updateAns = (e) => {
    let input1 = e.currentTarget;
    let inVal = input1.value;
    if (input1.id === "parentMask6") {
      let sel2 = document.getElementById("childMask6");
      while (sel2.childElementCount > 0) {
        sel2.remove(0);
      }
      for (let i = Number(inVal) + 1; i < 65; i++) {
        sel2.add(new Option(`(${2 ** (i - inVal)}) /${i}`, i));
      }
    }
  }

  return <Form className="subform">
    An IPv6 block with a mask of
    <select id="parentMask6" className="selects" onChange={updateAns}></select>
    contains
    <select id="childMask6" className="selects"></select> subnets
  </Form>
}
