import { useState } from 'react';
import { Navbar, Nav } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

export const Menu = (props) => {

  const titleText = "Bitlug Subnet Toolbox";
  const navigate = useNavigate();

  let binocImg = process.env.REACT_APP_HOST + "/img/icons/binocular.svg";
  let discordImg = process.env.REACT_APP_HOST + "/img/icons/discord-mark-blue.svg";
  let help = process.env.REACT_APP_HOST + "/doc/readme.html";

  let logo = process.env.REACT_APP_HOST + "/img/bitlug.svg";

  const reload = () => {
    navigate("/act/app/toolbox.html");
    props.sidebar(false);
  };
  let wideview = window.visualViewport.width > 1000;

  return (

    <Navbar variant="light" className="topborder">
      <Navbar.Brand>
        <div className="clickable d-flex text-center" onClick={reload} role="button" tabIndex="0" aria-label='home page'>
          <div className="logo"><img src={logo} alt="bitlug logo" /><div>Toolbox</div></div>
          <h1 className="title">{titleText}</h1>
        </div>
      </Navbar.Brand>
      <Nav className="position-absolute end-0 m-4">
        {wideview && <Nav.Link href="https://discord.gg/79375QqDD3" rel="nofollow">
          <div>
            <img
              className="m-1"
              width="36"
              height="36"
              src={discordImg}
              alt="Discord"
            ></img>
            <div className="center" style={{ "marginTop": "-10px", "marginLeft": "-5px" }}>Discord</div>
          </div></Nav.Link>}
        <Nav.Link href={help} className="text-contrast">
          <div><img
            className="m-1"
            width="36"
            height="36"
            src={binocImg}
            alt="docs"
          ></img>
            <div className="center" style={{ "marginTop": "-10px" }}>Docs</div></div>
        </Nav.Link>
        <Nav className="align-self-center m-3 d-none">
          {props.subscriber ? <img width="42"
            height="42"
            className="roundBox"
            alt="Login with Google"
            src={props.subscriber.picture}>
          </img> : <div id="googleButton"></div>}
        </Nav>
      </Nav>
    </Navbar>
  )
}