import { React, useState } from "react";
import { useFormik } from "formik";
import { Form, Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import { PatternTopo } from "../pattern_topo";
import YAML from "yaml";

export const PatternForm = (props) => {
  let addTab = process.env.REACT_APP_HOST + "/img/icons/newPatTap.svg";
  let initVal = {
    config: "prefix: 192.168.0.0\ntopo:\n- level: spine\n  size: 2\n  name: SP\n- level: leaf\n  size: 3\n  name: LF\nconnect:\n- levels: [spine, leaf]\n  type: p2p",
    config1: ""
  };

  const [topo, setTopo] = useState([YAML.parse(initVal.config), YAML.parse(initVal.config1)]);
  const [activeMap, setActiveMap ] = useState(0);
  const [solution, setSolution] = useState();
  const [jsonOut, setJsonOut] = useState(false);
  const [parent, setParent] = useState(false);

  const [buttonText, setButtonText] = useState("Submit");

  const updateWithChange = (e) => {
    let fv = activeMap===0?"config":"config1";
    formik.setFieldValue(fv, e.target.value);
    let yml = YAML.parse(e.target.value);
    let val = activeMap===0?[yml, topo[1]]:[topo[0], yml];
    setTopo(val);
  };

  const postValues = (values) => {
    if (!values.config || typeof values.config !== "string") return;
    let formIs = process.env.REACT_APP_HOST + "/api/v1/pattern";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    fetch(formIs, requestOptions).then(handleResponse);
    setButtonText("Working ");
  };

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values) => {
      postValues(values);
    },
  });

  const hasParent = () => {
    setParent(true);
    let initVal2 = "pattern: new\ntopo:\n- level: border\n  size: 4\n- level: $label\n  size: 2\nconnect:\n- levels: [$label, border]\n  type: p2p";
    formik.setFieldValue("config1", initVal2);
    setTopo([YAML.parse(initVal.config), YAML.parse(initVal2)]);
  }

  const setFormat = (val) => (e) => {
    setJsonOut(val);
    formik.submitForm(e);
  };

  function handleResponse(resp) {
    setButtonText("Submit");
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
          alert("response: " + resp.status);
        }
        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
      }
      setSolution(data);
      if (jsonOut) {
        let js = JSON.stringify(data, null, 2);
        props.setPattern(js);
      } else {
        let ym = YAML.stringify(data);
        props.setPattern(ym);
      }
    });
  }

  return (
    <Row>
      <Col>
        <Form className="subform" onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3 font-monospace" controlId="config">
          <div className="flex-split">
            <Form.Label>Pattern Description yaml</Form.Label>
            {!parent && <Button onClick={hasParent} variant="outline-light" className="addtab"><img
                width="38"
                height="38"
                src={addTab}
                alt="add"
              ></img></Button>}
            </div>
            {parent?(
              <Tabs onSelect={(t) => {setActiveMap(Number(t))}}>
                <Tab eventKey="0" title={topo[0] && Object.hasOwn(topo[0], "pattern")?topo[0].pattern:"default"}>
                  <Form.Control
                  type="text"
                  as="textarea"
                  rows="18"
                  value={formik.values.config}
                  onChange={updateWithChange}
                  onBlur={formik.handleBlur}
                  autoFocus={true}
                  style={{ minWidth: "240px" }}
                />
              </Tab>
              <Tab eventKey="1" title={topo[1] && Object.hasOwn(topo[1], "pattern")?topo[1].pattern:"new"}>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows="18"
                  value={formik.values.config1}
                  onChange={updateWithChange}
                  onBlur={formik.handleBlur}
                  autoFocus={true}
                  style={{ minWidth: "240px" }}
                />
              </Tab>
            </Tabs>):
            <Form.Control
              type="text"
              as="textarea"
              rows="18"
              placeholder="config"
              value={formik.values.config}
              onChange={updateWithChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
              style={{ minWidth: "240px" }}
            />}
          </Form.Group>
          <div>
            <Button
              variant={!jsonOut ? "secondary" : "outline-secondary"}
              onClick={setFormat(false)}
            >
              YAML
            </Button>
            <Button
              variant={jsonOut ? "secondary" : "outline-secondary"}
              onClick={setFormat(true)}
            >
              JSON
            </Button>
          </div>
          <Button className="blbutton" type="submit">
            {buttonText}
            {buttonText === "Working " ? (
              <div className="elipse">
                <span className="ladeda">...</span>
              </div>
            ) : null}
          </Button>
        </Form>
      </Col>
      <Col sm={12} md={6} className="topoMap">
        <PatternTopo map={topo} solution={solution} active={activeMap}></PatternTopo>
      </Col>
    </Row>
  );
};
