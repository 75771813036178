
import { Container } from 'react-bootstrap';
export const YouTube = (props) => {
    let url = "https://www.youtube.com/embed/" + props.vid;
    var ywidth = window.innerWidth > 827 ? 827:window.innerWidth - 100;
    var yheight = ywidth * 0.5614;
    return (
    <Container className="center">
        <iframe width={ywidth} height={yheight} src={url} title="How to use the Bitlug Subnet Calculator" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen={true}></iframe>
        </Container>
    )
}