import { React, useState } from "react";
import { Container } from "react-bootstrap";
import { EmbedForm } from "../components/forms/embed_form";
import { Bitmap } from "../components/bitmap";
import { Subnet } from "../components/subnet";
import { useHeaderTags } from "../components/custom_hook";

export const EmbedFlow = (props) => {
  useHeaderTags(props);
  const [subnet, setSubnet] = useState({});
  const [showDesc, setShowDesc] = useState(true);
  return (
    <>
      <Container>
        <EmbedForm showDesc={setShowDesc} setSub={setSubnet} />
      </Container>
      {!showDesc && (
        <Container>
          <Container className="chart mt inline">
          {subnet.decode ? <Subnet {...subnet.ipv4Det}/>:<Subnet {...subnet.embeded}></Subnet>}
          </Container>
          <div className="m-2"><span className="text-bold">IPv4 HEX val: </span>{subnet.ipv4Det.values[0].toString(16)}</div>
          
          {subnet.portList?<>
            <div className="m-2"><span className="text-bold">Port Set: </span>
            {subnet.portList[0]}-{subnet.portList[1]}, {subnet.portList[2]}-{subnet.portList[3]} . . . {subnet.portList[4]}-{subnet.portList[5]}, {subnet.portList[6]}-{subnet.portList[7]} 
            </div>
            </>:null
          }
          {!subnet.portList && subnet.mapT?<div className="m-2">The declared rules do not result in a port set</div>:null} 
          {subnet.mapT && <div className="m-2"><span className="text-bold">Note: </span>Currently support has been implemented for basic mapping rules as shown in <a href="https://datatracker.ietf.org/doc/html/rfc7597#appendix-A">Example 1 of RFC7597</a>. Additional support is planned for a future release </div>}

          <h5 className="docs">Bit Map</h5>
          <Bitmap
            subnet={subnet.embeded}
            conflict={subnet.conflicts}
            offset={subnet.offset}
            width={subnet.width}
            rfc={subnet.rfc6052}
          ></Bitmap>
          <hr/>
           {subnet.rfc6052 && <><div className="docs">
              Reference <a href="https://datatracker.ietf.org/doc/html/rfc6052#section-2.2">RFC6052 section 2.2 </a></div>
              <pre><code>
             +--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+<br/>
    |PL| 0-------------32--40--48--56--64--72--80--88--96--104---------|<br/>
    <span className={subnet.offset==='32'?"text-bold":null}>+--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+</span><br/>
    <span className={subnet.offset==='32'?"text-bg-white":null}>|32|     prefix    |v4(32)         | u | suffix                    |</span><br/>
    <span className={subnet.offset==='32'||subnet.offset==='40'?"text-bold":null}>+--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+</span><br/>
    <span className={subnet.offset==='40'?"text-bg-white":null}>|40|     prefix        |v4(24)     | u |(8)| suffix                |</span><br/>
    <span className={subnet.offset==='40'||subnet.offset==='48'?"text-bold":null}>+--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+</span><br/>
    <span className={subnet.offset==='48'?"text-bg-white":null}>|48|     prefix            |v4(16) | u | (16)  | suffix            |</span><br/>
    <span className={subnet.offset==='48'||subnet.offset==='56'?"text-bold":null}>+--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+</span><br/>
    <span className={subnet.offset==='56'?"text-bg-white":null}>|56|     prefix                |(8)| u |  v4(24)   | suffix        |</span><br/>
    <span className={subnet.offset==='56'||subnet.offset==='64'?"text-bold":null}>+--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+</span><br/>
    <span className={subnet.offset==='64'?"text-bg-white":null}>|64|     prefix                    | u |   v4(32)      | suffix    |</span><br/>
    <span className={subnet.offset==='64'||subnet.offset==='96'?"text-bold":null}>+--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+</span><br/>
    <span className={subnet.offset==='96'?"text-bg-white":null}>|96|     prefix                                    |    v4(32)     |</span><br/>
    <span className={subnet.offset==='96'?"text-bold":null}>+--+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+---+</span><br/>
                </code></pre></>}
        </Container>
      )}
      {showDesc && (
        <Container>
          <div className="docs paper">
            <h3>Description</h3>
            <p>Translate IPv4 to an IPv6 address or from IPv6 to IPv4 address using the algorithm described in RFC6052. The RFC recommends a bit
              mapping scheme for CIDRs /32, /40, /48, /56, /64 and /96. The "well known" prefix is 64:ff9b::/96, however any IPv6 subnet can be used.
              In addition to NAT64, this tool can embed any number of IPv4 bits into an IPv6 address starting from any offset, and will alert on any 
              bit collisions that might happen. This function may be useful in a dual-stacked
             environment where the operator would like to keep a defined mapping that allows syncronized security policy.
            </p>
            <p>MapT is used mostly by service providers to allow IPv4 end stations to reach IPv4 resources over an exclusive IPv6 domain.  Carrier grade NAT serves a similar purpose. MapT's advantage
              over CGNAT is that it is semi stateless. This is acomplished by embedding port set information into the IPv6 address where is can be considered as part of routing behavior.
            </p>
            <h3>Usage</h3>
            <p>
              Provide the
              base IPv6 subnet, the start position where the IPv4 bits will be inserted, and the IPv4
              subnet that will be inserted.  The mask on the IPv4 address will set how many bits will be
              carried into the IPv6 address. Any colliding bits are show in the bit map.
              </p>
              If the RFC6052 NAT is selected, then the embedder will implement the translation techniques
              as described in <a href="https://datatracker.ietf.org/doc/html/rfc6052">RFC6052: IPv6 Addressing of IPv4/IPv6 Translators</a> 
              <p>To decoded NAT64, enter the encoded IPv6 value with a /128 mask, and select the offset that 
                was used to encode the IPv4. Leave the IPv6 field blank, and select rfc6052.  The encoded IPv4
                address will be returned. Also note the bit map, bits used as the IPv4 address are shown as blue
                and orange.
              </p>
              <p>Select MapT to explore <a href="https://datatracker.ietf.org/doc/html/rfc7599">RFC7599</a>.  Note that additional functionality is planned for future releases.  The current release only covers basic mapping rules.</p>
          </div>
        </Container>
      )}
    </>
  );
};
