import "./css/custom.css";
import { useState, useEffect, useRef, useContext } from 'react';
import { Container, Navbar, Nav } from "react-bootstrap";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { SubnetFlow } from "./pages/subnet_flow";
import { EmbedFlow } from "./pages/embed_flow";
import { ToolSelector } from "./pages/tool_selector";
import { PrefixFlow } from "./pages/prefix_flow";
import { AllocateFlow } from "./pages/allocate_flow";
import { PatternFlow } from "./pages/pattern_flow";
import { DifferenceFlow } from "./pages/difference_flow";
import { TreeFlow } from "./pages/tree_flow";
import { PlannerFlow } from "./pages/planner_flow";
import { GameFlow } from "./pages/game_flow";
import { RouteFlow } from "./pages/route_flow";
import { SplashDown } from "./pages/splashdown_flow";
import { PracticeFlow } from "./pages/practice_flow";
import { Sidebar } from "./components/sidebar";
import { isExpired, decodeToken } from "react-jwt";
import { Menu } from "./components/menu";
import { UserContext } from "./components/user_context";

import "./css/submap.css";

function App() {

  const [titleText, setTitleText] = useState("Bitlug Subnet Toolbox");
  const [refTool, setRefTool] = useState();
  let about = process.env.REACT_APP_HOST + "/doc/about.html";
  let legal = process.env.REACT_APP_HOST + "/doc/legal.html";
  let privacy = process.env.REACT_APP_HOST + "/doc/privacy.html";
  let wideview = window.visualViewport.width > 1000;
  let userContext = useContext(UserContext);

  const [userJwt, setUserJwt] = useState();
  const [subscriber, setSubscriber] = useState();
  const [showSidebar, setShowSidebar] = useState(true);
  const isLoaded = useRef(true);

  const TitleWrapper = ({ children }) => {
    useEffect(() => {
      setTitleText(children.props.title);
      setShowSidebar(true);
    }, [children.props.title])
    return children;
  }

  const handleTokResponse = async (resp) => {
   
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      if (data.length === 0) {
        return;
      }
      setSubscriber(data);
      userContext.email = data.userId;
      userContext.valid = true;
    });
  }

  const handleCredentialResponse = async (resp) => {
    setUserJwt(decodeToken(resp.credential))
    let tokenUrl = process.env.REACT_APP_HOST + "/api/v1/posttok";
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ jwt: resp.credential }),
    };
    fetch(tokenUrl, requestOptions).then(handleTokResponse);
  }

  const handleUserPref = async (resp) => {
    if (resp === undefined)
      return;
    let upref = resp.text().then((text) => {
      return JSON.parse(text);
    });
  }

  const handleGuest = () => {
      google.accounts.id.prompt();
  }

  useEffect(() => {
    /* global google */
    const initGoog = () => {
      google.accounts.id.initialize({
        client_id: '54858377389-4moq6hffeameu34886qj7lfut9aeor17.apps.googleusercontent.com',
        callback: handleCredentialResponse,
        nonce: "",
        context: "signup",
        itp_support: "false",
        ux_mode: "popup"
      });
      google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        {
          theme: "filled_blue",
          size: "medium",
          shape: "pill",
          type: "standard",
          text: "signup_with"
        }
      );
    }
    const readCookie = () => {
      let tokenUrl = process.env.REACT_APP_HOST + "/api/v1/userPref";
      const requestOptions = {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };
      fetch(tokenUrl, requestOptions).then(handleTokResponse).then(handleUserPref);

    }
    if (isLoaded && isLoaded.current && typeof google !== 'undefined') {
      initGoog();
      if (userContext.valid === false) {
        readCookie();
      }
    }
  }, [isLoaded.current]);

  const reload = () => {
    document.location.href = "/act/index.html";
  };

  let tape = !document.location.href.endsWith("/act/index.html");

  return <BrowserRouter>
      <>
        <Menu sidebar={setShowSidebar} subscriber={subscriber} />
        {showSidebar && <Sidebar refTool={refTool} resetRef={() => setRefTool(null)} setTitle={setTitleText} />}
        <div className="App">
        <Container fluid className={wideview ? "mainContainer" : ""} style={{"paddingBottom": "60px"}}>
          <Routes>
            <Route 
              path="/act/index.html"
              element={<SplashDown sidebar={setShowSidebar}/>}
            ></Route>
            <Route
              path="/act/app/toolbox.html"
              element={<ToolSelector wideview={wideview} sidebar={setShowSidebar} />}
            ></Route>
            <Route
              path="/act/app/subnet/:sid"
              element={<TitleWrapper><SubnetFlow title="Subnet Calculator Tool" /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/subnet.html"
              element={<TitleWrapper><SubnetFlow title="Subnet Calc Tool"
                desc="Discover the subnet hierarchy with this advanced graphical IPv4 & IPv6 calculator. Provides binary network and host information for any CIDR, subnet or prefix" /></TitleWrapper>}
            ></Route>
            <Route path="/act/app/embed.html"
              element={<TitleWrapper><EmbedFlow title="NAT64 Translator"
                desc="RFC6052 NAT64 calculator for 64:ff9b::/96 or any IPv6 to IPv4 translation. Or simply embed an IPv4 subnet anywhere inside of IPv6 address" /></TitleWrapper>}>
            </Route>
            <Route
              path="/act/app/prefix.html"
              element={<TitleWrapper><PrefixFlow title="Prefix-List Tool"
                desc="Eliminate overlapping subnets, sort IPv4 and IPv6 prefixes, or convert from CIDR to either mask or wildcards notation with this prefilx list calculator tool"
                setRefTool={setRefTool} width={wideview} /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/routes.html"
              element={<TitleWrapper><RouteFlow title="Route Table Analyzer"
                desc="Uncover ineffeciencies in a route table. Find possible summary routes. Or just get some insights into the address plan and capacities"
              /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/allocate.html"
              element={<TitleWrapper><AllocateFlow title="Subnet Generator"
                desc="Allocate subnets to cover a specific number of IP addresses. Specify the minimum and maximum subnet sizes. Quickly generate configuration snippets using the results" /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/pattern.html"
              element={<TitleWrapper><PatternFlow title="Pattern Designer"
                desc="Use a YAML file to define common design patterns used in an IP address plan. The output can be used with jinja2 templates" /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/planner.html"
              element={<TitleWrapper><PlannerFlow title="IPv4 IPv6 Subnet Planner"
                desc="Design an IPv4 and IPv6 address plan for the WAN using three different size branches. Allows for multiple service vlans and point-to-point links" /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/diffs.html"
              element={<TitleWrapper><DifferenceFlow title="Subnet Diff Tool"
                desc="Compare two lists of subnets to find common or conflicting prefixes. Resolve prefix coverage conflicts and identify summaries with missing subnets" /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/tree"
              element={<TitleWrapper><TreeFlow title="Subnet Tree Tool" /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/tree.html"
              element={<TitleWrapper><TreeFlow title="Subnet Tree Tool"
                desc="Find a summary containing multiple subnets, or find a subnet that covers multiple IP addresses. Discover the CIDR hierarchy present in any list of IPv4 or IPv6 prefixes" /></TitleWrapper>}
            ></Route>
            <Route
              path="/act/app/challenge.html"
              element={<TitleWrapper><GameFlow title="Subnet Challenge"
                desc="Practice subnetting with this game of skill that will challenge your understanding of subnet sizing, masks and CIDRs"
              /></TitleWrapper>
              }
            ></Route>
            <Route
              path="/act/app/practice.html"
              element={<TitleWrapper><PracticeFlow title="Network Practice"
                desc="Prepare for your next certification with some sample questions to test your knowledge"
              /></TitleWrapper>
              }
            ></Route>
            <Route
              path="/act/"
              element={<Navigate replace to="/act/index.html" />}
            ></Route>
          </Routes>

        </Container>
        </div>
      </>
      <Navbar fixed="bottom" >
          <Container fluid className="justify-content-center footer">
            <Nav.Link href={about} rel="nofollow" aria-label="About" className="docs">
              About
            </Nav.Link>
            <Nav.Link href={legal} rel="nofollow" aria-label="Terms" className="docs">
              Terms
            </Nav.Link>
            <Nav.Link href={privacy} rel="nofollow" aria-label="Privacy" className="docs">
              Privacy
            </Nav.Link>
          </Container>
        </Navbar>
  </BrowserRouter>
}

export default App;
