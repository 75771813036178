import { React, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { useFormik } from "formik";

export const EmbedForm = (props) => {

  const [showMapT, setShowMapT] = useState(false);

  let initVal = {
    ipv4: "",
    ipv6: "64:ff9b::/96",
    from: "96",
    rfc6052: true,
    mapT: false,
    rule6: "",
    ipv4addr: "",
    port: 1024,
    share: 256
  }

  const toggleRFC = (e) => {
    if (!formik.values.rfc6052) {
      formik.setFieldValue('ipv6', '64:ff9b::/96');
      formik.setFieldValue('from', '96');
      formik.setFieldValue('mapT', false);
      document.getElementById('mapT').checked = false;
      setShowMapT(false);
    } else {
      formik.setFieldValue('from', '64');
      formik.setFieldValue('ipv6', '');
    }
    formik.handleChange(e);
  }

  const toggleMapT = (e) => {
    if (showMapT === true) {
      setShowMapT(false);
    } else {
      setShowMapT(true);
      formik.setFieldValue("mapT", true);
      formik.setFieldValue("rfc6052", false);
      document.getElementById("rfc6052").checked = false;
      formik.setFieldValue('ipv6', '2001:db8::/40');
      formik.setFieldValue('rule6', '2001:db8:12:3400::/56');
      formik.setFieldValue("ipv4", "192.0.2.0/24")
    }
  }

  const updateWithChange = (e) => {
    formik.handleChange(e);
    formik.submitForm(e);
  }

  const formik = useFormik({
    initialValues: initVal,
    onSubmit: (values) => {
      postValues(values);
    },
  });

  const clearForm = () => {
    props.showDesc(true);

    if (formik)
      formik.resetForm();
  };

  const postValues = (values) => {
    let formIs = process.env.REACT_APP_HOST + "/api/v1/embed";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    };
    fetch(formIs, requestOptions).then(handleResponse);
  };

  function handleResponse(resp) {
    return resp.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
          alert("response: " + resp.status);
        }

        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
      }
      props.setSub(data);
      props.showDesc(false);
    });
  }

  const getRow2 = () => {
    if (formik.values.rfc6052) {
      return <>
        <Form.Group className="mb-3"
          controlId="from"
          onChange={updateWithChange}
          value={formik.values.from} >
          <Form.Label>Start position</Form.Label>
          <Form.Select aria-label="starting from position" defaultValue="96">
            <option value="32">32</option>
            <option value="40">40</option>
            <option value="48">48</option>
            <option value="56">56</option>
            <option value="64">64</option>
            <option value="96">96</option>
          </Form.Select>
        </Form.Group>
      </>
    }
    if (showMapT) {
      return <>
        <Form.Group className="mb-3" controlId="rule6">
          <Form.Label>IPv6  Rule, Subnet
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="ipv4, leave blank to decode"
            value={formik.values.rule6}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoFocus={true}
          />
        </Form.Group>
      </>
    }
    return <>
      <Form.Group className="mb-3" controlId="from">
        <Form.Label>Start position</Form.Label>
        <Form.Control
          type="number"
          placeholder="32"
          value={formik.values.from}
          onChange={updateWithChange}
          onBlur={formik.handleBlur}
          autoFocus={true}
        />
      </Form.Group>
    </>
  }


  return (
    <Form className="subform" onSubmit={formik.handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="ipv6">
            <Form.Label>{showMapT ? "Domain IPv6" : "IPv6 Subnet"}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ipv6"
              value={formik.values.ipv6}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
          {getRow2()}

          <Form.Group className="mb-3" controlId="ipv4">
            <Form.Label>{showMapT ? "IPv4 Rule, Subnet" : "IPv4 Subnet"}</Form.Label>
            <Form.Control
              type="text"
              placeholder="ipv4, leave blank to decode"
              value={formik.values.ipv4}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoFocus={true}
            />
          </Form.Group>
          <Form.Group controlId="rfc6052">
            <Form.Switch
              defaultChecked={true}
              type="switch"
              id="rfc6052"
              label="RFC6052 NAT64"
              value={formik.values.rfc6052}
              onChange={toggleRFC}
              onBlur={formik.handleBlur}
            ></Form.Switch>
          </Form.Group>
          <Form.Group controlId="mapT">
            <Form.Switch
              defaultChecked={false}
              type="switch"
              id="mapT"
              label="MAP-T (RFC7599)"
              value={formik.values.mapT}
              onChange={toggleMapT}
              onBlur={formik.handleBlur}
            ></Form.Switch>
          </Form.Group>
        </Col>
        {showMapT === true && <Col>

          <Form.Group className="mb-3" controlId="port"
            value={formik.values.port}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoFocus={true}
          >
            <Form.Label>Start Port</Form.Label>
            <Form.Select aria-label="starting from position" defaultValue="1024">
              <option value="0">0</option>
              <option value="1024">1024</option>
              <option value="2048">2048</option>
              <option value="4096">4096</option>
              <option value="8192">8192</option>
              <option value="16384">16384</option>
            </Form.Select>
          </Form.Group>

        </Col>}
      </Row>
      <Button className="blbutton" type="submit">
        Translate
      </Button>
      <Button id="clrbtn" className="clrbutton" onClick={clearForm}>Clear</Button>
    </Form>
  );
}