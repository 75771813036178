import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { PlannerForm } from '../components/forms/planner_form';
import { PlannerView } from '../components/planner_view';
import { useHeaderTags } from "../components/custom_hook";

export const PlannerFlow = (props) => {

    const [plan, setPlan] = useState();
    const [viewType, setViewType] = useState("YAML")
    const [showDesc, setShowDesc] = useState(true);

    useHeaderTags(props);

    return (
        <Container>
            <PlannerForm setPlan={setPlan} viewType={viewType} setViewType={setViewType} showDesc={setShowDesc} />
            {showDesc &&

                <div className="docs paper">
                    <h3>Description</h3>
                    <p>
                        This tool can assist with a condensed address plan and data model that allocates a fixed amount of address space over the branch and wide area network.  It supports both IPv4 and IPv6.
                        The plan is split into 3 templates, small, medium and large - although these are nothing more than convenient labels.  For each template, define the number subnets, and the size of each subnet.
                        These are commonly mapped to vlans where a vlan loosely translates to a service offered by the network.  For example, employees, guest, iot, or voice.  Create as many service vlans
                        as needed and the max host count for each.  Each template may offer unique services.  Then define a block of IPv4 and optionally IPv6 address space. Finally define how many /30
                        subnets will be needed for the p2p links within the template.  The tool will create an address plan that covers the number of branches that implement each template.  The address plan can be represented in json or yaml for
                        consumption in some automation such as Ansible, or a pure table that can be refrenced by network implementation engineers.

                    </p>
                    <h3>Overview</h3>
                    <p>
                        Its not uncommon for network administrators to allocate the WAN / Branch block from RFC1918 10.0.0.0 /8 using an octet mapping scheme.
                        For example, the 2nd octets maps into a branch location, and the 3rd octet into the service.
                         <br />
                        <code>10.x.y.0 /24:  Global block</code><br />
                        <code>   | ^------:  Y is the service number, vlan mapping</code><br />
                        <code>   ^--------:  X is the branch number.  0 - 255 values are available. </code><br />
                        One advantage of this approach is that it is very simple to administer when the network has less than 256 branches and where each service will have less than
                        254 endpoints.  There are some drawbacks though. It is not an efficient use of address space. Summary addressing becomes very difficult.  If the company is acquired or sold, there is a greater chance of wide scale address collisions with the acquiring company.
                        The schema can also be a bit rigid making it difficult to adopt new technologies such as SDWAN, where services often find their way into macrosegments where address assignments can happen
                        independent of the underlay topology.  A guest segment might be an example. That segment could carry users to the enterprise DMZ without regard to the branch address plan.
                        <p>This tool creates an effecient address plan by eliminating wasted address space, maximizing summarization, and isolating infrastructure point to point links from service vlans</p>

                    </p>
                    <h3>Usage</h3>
                    Define address blocks for both IPv4 and IPv6 that are large enough to cover the number of IP endpoints.  Don't worry if the block is larger then it needs to be.  The tool will only allocate what is needed.  Then decide how many templates will be used.  Unused templates may be left blank.
                    For each template, add vlans to cover the various services, and define how many endpoints with participate at the largest location described by each template.  Then
                    specify how many branches will be using that template.
                    <p>
                        <p>After submitting, the tool will create an address plan that includes an aggregate for each branch.  This will cover all vlans and p2p subnets.  The tool also generates a 2nd aggregate that just
                            covers the point to point links. Having an aggregate that covers p2p links allows ACLs to be crafted specific for infrastructure policy.  The output can be presented in either JSON
                            or YAML.  This simplifies automation tasks.  You may also display the output in a table that provides the basic information.
                        </p>

                    </p>
                </div>

            }
            {plan && <PlannerView plan={plan} viewType={viewType} />}
        </Container>
    )
}