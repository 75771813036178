import { React } from "react";
import { Row, Col, Stack } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

let toolboxImg = process.env.REACT_APP_HOST + "/img/toolbox.svg";

export const SplashDown = (props) => {

    const navigate = useNavigate();

    const reload = () => {
        navigate("/act/app/toolbox.html");
        props.sidebar(false);
    };


    return <div className="splashBackground">
        <Stack className="stackFiller">
            &nbsp;
        </Stack>
        <Stack onClick={reload}>
            <Row className="justify-content-center spashStripe">
                <Col xs={4} lg={4} >
                    <div className="splashText">
                        <h2 className="text-center bitlug-text text-outline">The Super Useful Subnet Toolbox</h2>
                    </div>
                </Col>
                <Col xs lg={6} >
                    <div>
                        <img className="splashImg" src={toolboxImg} alt="Bitlug Toolbox"/>
                    </div>
                </Col>
            </Row>
        </Stack>
        <Stack gap={4} className="stackFiller">
            &nbsp;<br />&nbsp;
            <div className="herobox">
                <h4 style={{"fontWeight": "600", "fontFamily": "Libre Franklin"}}>If you are building a house, you need tools. If you are building an IP network, you need Bitlug.</h4>
            </div>
        </Stack>
    </div>
}