
import { useState, useRef, useEffect, Fragment } from 'react';
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { RouteForm } from "../components/forms/route_form";
import { useHeaderTags } from "../components/custom_hook";
import { useWindowResize } from "../components/useWindowResize";
import { SummaryMap } from "../components/summary_map";
import { SummaryFolder } from "../components/summary_folder"
import { Leaked } from "../components/leaked";

export const RouteFlow = (props) => {
  useHeaderTags(props);
  const [routes, setRoutes] = useState();
  const [showHelp, setShowHelp] = useState(true);
  const [activePanel, setActivePanel] = useState("none");
  const [highRow, setHighRow] = useState(-1);
  const canvasRef = useRef();
  const [agePercent, setAgePercent] = useState(100);
  const [metricPercent, setMetricPercent] = useState(100);
  const [force, setForce] = useState(false);

  let w = window.width;
  let c = w / 2;
  useWindowResize(canvasRef);

  if (canvasRef && canvasRef.current) {
    let box = canvasRef.current.getBoundingClientRect();
    w = box.width;
    c = w / 2;
  }

  const scaler = (x) => {
    return Math.exp(-(100 - x) / 20);
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0')

  const intToAge = (sec) => {
    let remain = sec;
    let y = Math.floor(sec / 31536000);
    remain -= y * 31536000;
    let w = Math.floor(remain / 604800);
    remain -= w * 604800;
    let d = Math.floor(remain / 86400);
    remain -= d * 86400;
    let h = Math.floor(remain / 3600);
    remain -= h * 3600
    let m = Math.floor(remain / 60);
    remain -= m * 60;
    let s = Math.floor(remain / 60);
    if (y > 0) {
      return `${y}y${w}w`;
    }
    if (w > 0) {
      return `${w}w${d}d`;
    }
    if (d > 0) {
      return `${d}d${h}h`;
    }
    return `${zeroPad(h, 2)}:${zeroPad(m, 2)}:${zeroPad(s, 2)}`;
  }

  const visible = (val) => {
    return val > -w && val < w;
  }

  const setPosition = (metric, maxMetric, age, maxAge) => {
    let metricRatio = 0.9 * metric / (maxMetric + 0.1);
    let ageRatio = age / (maxAge + 100);
    let angleRad = 2.0 * Math.PI * ageRatio / scaler(agePercent);
    if (angleRad > 2 * Math.PI) {
      angleRad = 2 * Math.PI;
    }
    let xPos = metricRatio * Math.cos(angleRad) / (scaler(metricPercent) ** 2.5);
    let yPos = metricRatio * -Math.sin(angleRad) / (scaler(metricPercent) ** 2.5);
    if (Math.abs(xPos) > w || Math.abs(yPos) > w) {
      xPos = w * Math.cos(angleRad);
      yPos = w * -Math.sin(angleRad);
    }
    return [xPos, yPos, angleRad];
  }

  const updateRange = (e) => {
    let type = e.currentTarget.dataset.type;
    if (type === "age") {
      setAgePercent(Number(e.currentTarget.value) + 0.1);
    } else if (type === "metric") {
      setMetricPercent(Number(e.currentTarget.value) + 0.1);
    }
    setHighRow(-1);
  }

  const onDefault = (k) => {
    if (routes.defaultIfaces) {
      return routes.defaultIfaces.filter(df => df === k).length > 0;
    }
    return false;
  }

  const getColor = (admin) => {
    if (admin === 200) {
      return "grey";
    }
    if (admin < 145) {
      return "blue";
    }
    return "green";
  }

  const setPanel = (panel) => {
    if (activePanel === panel) {
      setActivePanel("none");
    } else {
      setActivePanel(panel);
    }
  }

  useEffect(() => {
    if (routes) {
      setForce(true);
    }
  }, [routes]);

  const ifolder = () => {
    let counts = Object.keys(routes.routeCount).map(k => Object(
      {
        "iface": k,
        "count": routes.routeCount[k],
        "default": onDefault(k),
      }
    ));
    return counts.sort((a, b) => a.count > b.count ? -1 : 1);
  }

  const resetDial = () => {
    setHighRow(-1);
    document.getElementById("agerange").value = '100';
    document.getElementById("metricrange").value = '100';
    setAgePercent(100);
    setMetricPercent(100);
  }

  const refresh = () => {
    setActivePanel("none");
    setHighRow(-1);
    setForce(true);
  }

  const getFormat = (tag) => {
    switch (tag) {
      case 'f':
        return 'folder';
      case 'x':
        return 'text-contrast';
      default:
        return ''
    }
  }

  const arc = (x, y) => {
    let r = Math.sqrt((x - c) ** 2 + (y - c) ** 2);
    if (y > c)
      return `M ${r + c} ${c} A ${r} ${r} 0 1 0 ${x} ${y}`;
    return `M ${r + c} ${c} A ${r} ${r} 0 0 0 ${x} ${y}`;
  }

  const followSub = (e) => {
    let tar = e.currentTarget.textContent.replace("/", "_");

  }

  const highlightRow = (e) => {
    let target = Number(e.currentTarget.dataset.table);
    setHighRow(target);

  }

  routes?.tables.forEach(table => {
    let maxMetric = routes.maxMetric;
    let maxAge = routes.maxAge;
    let pos = setPosition(table.metric, maxMetric, table.age, maxAge);
    table.xPos = pos[0];
    table.yPos = pos[1];
    table.rad = pos[2];
  });

  if (isNaN(c)) {
    c = 0;
  }

  return (
    <>
      <Container>
        <RouteForm
          setRoutes={setRoutes}
          showHelp={setShowHelp}
          refresh={refresh}
        ></RouteForm>
        {showHelp &&
          <div className="docs paper">
            <h3>Description</h3>
            <h5>This tool is experimental</h5>
            <p>This is a sneak peak of the route table analisys tool, and is being released in realtime as functionality is developed.
            </p>
            <br />
            <h3>Usage</h3>
            <p>Paste in a modestly sized IOS-XE route table as text. The tool will
              provide some useful observations.  Should be less than 3000 routes. 'show ip route x/y longer-prefix'
              to scope down what the tool is analyzing.
            </p>

          </div>}
      </Container>
      {!showHelp && (
        <Container ref={canvasRef}>
          <Row>
          {routes.message &&
            <Container><div className="msg">{routes.message}.</div></Container>}
            <Col id="subs">
              <div>
                <div>&nbsp;</div>
                <h5>
                  Routes per Next Hop
                </h5>
                <pre className="full-panel">
                  <Button variant="outline-primary" className="rndBtn" onClick={() => setPanel("tree")}>{activePanel === "tree" ? '-' : '+'}</Button>
                  <code>
                    <Row>
                      {routes && routes.nextHops &&
                        Object.keys(routes.nextHops).map(k => {
                          return (
                            <Col className="half-panel" key={k} >
                              <div className="colHeader"><div>Next Hop {k} has {routes.nextHops[k]?.routeCount} routes</div>
                                <div>{routes.nextHops[k]?.ifName}</div>
                                <div>{routes.nextHops[k]?.onDefault && "Default Path"}</div></div>
                              {activePanel === "tree" && routes.nodes[k].map((subnet, id) => {
                                return <SummaryFolder key={id} folder={subnet}/>
                              })
                              }
                            </Col>
                          );
                        })}
                    </Row>
                  </code>
                </pre>
              </div>
            </Col>
          </Row>
          <hr />
          <Row>
            <h4><Button variant="outline-primary" className="rndBtn" style={{ "marginRight": "15px" }} onClick={() => setPanel("leaks")}>{activePanel === "leaks" ? '-' : '+'}</Button>Leaked Summaries</h4>
            {activePanel === "leaks" ? <Col><Leaked leaked={routes.ifaceRtConflicts} /></Col> : <div className="docs">Routes where an enclosed more specific route is using a different interface</div>}
          </Row>
          <hr />
          <Row>
            <h4><Button variant="outline-primary" className="rndBtn" style={{ "marginRight": "15px" }} onClick={() => setPanel("ifacemap")}>{activePanel === "ifacemap" ? '-' : '+'}</Button>Interface Map</h4>
            <Col>{activePanel === "ifacemap" ? <SummaryMap panel={activePanel} width={w} summaries={routes.summaries} ifaces={routes.interfaces} ifolder={ifolder()} recursion={routes.recursionCache} defPath={routes.defaults}/> :
              <div className="docs">Plots interfaces clockwise around a circle based on the route count.  The default spoke is highlighted and is usually at the top position</div>}</Col>
          </Row>
          <hr />
          <Row>
            <h4>
              <Button variant="outline-primary" className="rndBtn" style={{ "marginRight": "15px" }} onClick={() => setPanel("ageplot")}>{activePanel === "ageplot" ? '-' : '+'}</Button>Age vs Metric plot</h4>
            {activePanel === "ageplot" ? <div className="full-panel">
              <svg height={w} width={w} className="full-panel shiftleft">
                <line x1={c} y1={0} x2={c} y2={w} stroke="black" />
                <line x1={0} y1={c} x2={w} y2={c} stroke="black" />
                {routes.tables.map((table, id) => {
                  return (<Fragment key={id}>
                    {table.rad < 2 * Math.PI && <circle className="clickable" cx={c * table.xPos + c} cy={c * table.yPos + c} r={table.defaultPath ? 24 : 12} strokeWidth={table.routes.length} stroke={getColor(table.admin)} fill={id === highRow ? "red" : "white"} data-table={id} onClick={highlightRow}></circle>}

                    {visible(table.xPos) && <path d={arc(table.xPos * c + c, table.yPos * c + c)} stroke={id === highRow ? "red" : getColor(table.admin)} fill="none" />}
                    {table.xPos > 0 && Math.abs(table.yPos) < 0.000001 && table.age > 0 &&
                      <circle cx={c} cy={c} r={c * Math.sqrt(table.xPos ** 2 + table.yPos ** 2)} stroke={id === highRow ? "red" : getColor(table.admin)} fill="none" />}
                    <line x1={c} y1={c} x2={c * table.xPos + c} y2={c * table.yPos + c} stroke={table.defaultPath ? 'orange' : 'lightgrey'} strokeWidth={table.defaultPath ? '7px' : '1px'} />
                  </Fragment>
                  )
                })}
              </svg>
              <code>
                {highRow > -1 &&
                  <div className="half-panel code">{routes.tables.filter((t, i) => { return i === highRow }).map(table => {
                    return (<div>
                      <div>{table.routes.length} routes, age: {table.ageStr} ({(table.age * 100 / (routes.maxAge + 0.1)).toFixed(2)}%), metric: {table.metric}, admin: {table.admin}</div>
                      {table.routes.map(r => {
                        return <div>{r.dest}, via {r.nextHop} {r.iface} </div>;
                      })}
                    </div>
                    )
                  })}
                  </div>}
              </code>
              <div><strong>Scale</strong>
                {(agePercent !== 100 || metricPercent !== 100 || highRow > -1) && <Button className="m-2" variant="primary primary-outline" onClick={resetDial} >Reset</Button>}
                <Form className="slider-panel" force={force.toString()}>
                  <Form.Label>Age: {intToAge(scaler(agePercent) * routes.maxAge)}</Form.Label>
                  <Form.Range defaultValue={100} data-type="age" onChange={updateRange} id="agerange" />
                  <Form.Label>Metric: {Math.floor((scaler(metricPercent) ** 2.5) * routes.maxMetric)}</Form.Label>
                  <Form.Range defaultValue={100} data-type="metric" onChange={updateRange} id="metricrange" />
                </Form>
              </div>
            </div> :
              <div className="docs">Groups routes by age and metric and presented as a polar plot.  Routes with a similar age tend to share a common dependancy in the topology. This can be insightful when compared to the route's metric. Lower
                admin routes are shown in blue, higher admin routes are in green.  Higher admins are often redistributed from external.  The thickens of the circle indicates the number of routes in this metric vs age grouping </div>}

          </Row>
        </Container>
      )}
    </>
  );
}