import { React, useState, useEffect, useRef } from 'react';
import { useFormik } from "formik";
import { Form, Button } from "react-bootstrap";

export const TreeForm = (props) => {

    const getPrePopString = () => {
      if (props.prePopulate) {
        return props.prePopulate.fromNet.replace("_", "/") + "-" + props.prePopulate.toNet.replace("_", "/");
      }
      return "missing";
    }

    const chkbx = useRef();
    const fldsw = useRef();

    let initVal = props.prePopulate?{
      nets: getPrePopString(),
      free: true,
      folder: true
    }:{
        nets: "",
        free: false,
        brief: false,
        folder: true
    }

    const [ buttonText, setButtonText ] = useState("Submit")

    const clearForm = () => {
      props.showHelp(true);
      props.doRefresh(true);
      fldsw.current.checked=true;
      if (formik)
        formik.resetForm();
    };

    useEffect(() => {
      if (props.prePopulate) {
        formik.submitForm();
      }
      fldsw.current.checked=true;
    }, []);

    const postValues = (values) => {
        if (!values.nets || typeof(values.nets) !== "string") return;
        let formIs = process.env.REACT_APP_HOST+"/api/v1/tree";
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        };
        fetch(formIs, requestOptions).then(handleResponse);
        setButtonText("Working ");
        props.doRefresh(false);
    };

    const formik = useFormik({
        initialValues: initVal,
        onSubmit: (values) => {
          postValues(values);
        },
      });

      if (chkbx.current && props.prePopulate && formik) {
        if (formik.values.free)
          chkbx.current.checked=true;
      }
  

    function handleResponse(resp) {
      setButtonText("Submit");
      if (!resp.ok) {
        alert("Input Error (we'll start working on this)");
      }
    return resp.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
            alert("response: " + resp.status);
        } 
        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
        }
        props.setPrefixes(data);
        props.showHelp(false);
        props.doRefresh(true);
    });
    }


      
    return (
        <Form className="subform" onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId="nets">
          <Form.Label>Subnets</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows="6"
            placeholder="ipv4 or ipv6 subnets and addresses"
            value={formik.values.nets}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoFocus={true}
            aria-describedby="prefixDialog"
          />
        </Form.Group>
        <Form.Group controlId="folder">
          <Form.Check
            ref={fldsw}
            type="switch"
            label="Auto Gen Folders"
            value={formik.values.folder}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Group>
        <Form.Group controlId="free">
          <Form.Check
            ref={chkbx}
            type="switch"
            label="Include Missing"
            value={formik.values.free}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Group>
        {formik.values.free && formik.values.folder?<Form.Group controlId="brief">
          <Form.Check
            ref={chkbx}
            type="switch"
            label="Brief"
            value={formik.values.brief}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Form.Group>:<div style={{"marginBottom": "2px"}}>&nbsp;</div>}
        <Button className="blbutton" type="submit">
          {buttonText}{buttonText==='Working '?(<div className="elipse">
                <span className="ladeda">...</span>
              </div>):null}
        </Button>
        <Button className="clrbutton" onClick={clearForm}>Clear</Button>
      </Form> 
    )
}