import { useState, useRef, useEffect } from 'react'

export const SummaryFolder = (props) => {

    const graphRef = useRef();
    var yOffset = 0;
    if (props.canvasRef && props.canvasRef.current)
      yOffset = props.canvasRef.current.getBoundingClientRect().y;
    if (graphRef.current) {
      props.folder.y = graphRef.current.getBoundingClientRect().y - yOffset;

    }

    const [ expanded, setExpanded ] = useState(props.folder.level < 2?true:false);

    const toggleExpand = () => {
        let newExpand = expanded?false:true;
        props.folder.expand = newExpand;
        setExpanded(newExpand);
        props.setRefresh(true);
    }

    let nhIfaceNames = [];

    if (props.recursion) {
      props.folder.nextHops.map(nh => {
          let names = props.recursion[nh.ifaceName];
          if (names && names.length > 0) {
            nhIfaceNames.push({...nh, "recur": names});
          } else {
            nhIfaceNames.push(nh);
          }
      })
    }

    const getFormat = (tag) => {
        switch (tag) {
          case 'f':
            return 'folder clickable';
          case 'x':
            return 'text-success';
          default:
            return ''
        }
      }

    const nSpaces = (n, tag) => {
        if (n === 0) {
          return;
        }
        if (tag === 'x') {
          return ("(  ".repeat(n));
        }
        return (
          "|  ".repeat(n)
        );
      };

    return <> 
        <span onClick={toggleExpand}>{props.folder.members && props.folder.members.length > 0?
            <svg width={15} height={15} className="clickable">
                <circle cx='6' cy='6' r='5' stroke={expanded?'black':'white'} fill={expanded?'white':'black'}/>
            </svg>
            :
            <span className={getFormat(props.folder.tag)}>|-</span>}
        - <span ref={graphRef} className={getFormat(props.folder.tag)}>{props.folder.prefix}</span>
        {props.folder.desc && <span className="text-muted">&nbsp;({props.folder.desc})</span>}
        {nhIfaceNames.length > 0 ?
          nhIfaceNames.map(nh => {
            return <span className={props.selected === nh.ifaceName?"m-3 text-bold":"m-3"}>{nh.ifaceName}{nh.recur &&
               <span> r-&gt; {nh.recur.map(nhn => 
                  <span key={nhn}>{nhn} </span>
                )} </span>}
              </span>
          })
          :null
        }
        {props.folder.tag === 'f' && props.folder.prefix.indexOf(":") < 0?<span className="m-3">({(props.folder.percent * 100).toFixed(0)}%)</span>:null}
        <br/></span>
        {props.folder.members && expanded ? props.folder.members.map((member, i) => {
            return <div key={i}><span>{nSpaces(member.level, member.tag)}</span><span><SummaryFolder setRefresh={props.setRefresh} setFocus={props.setFocus} canvasRef={props.canvasRef} folder={member} selected={props.selected} /></span></div>
        }):null}
    </>

}