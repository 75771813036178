export const GamePartMenu = (props) => {

    return (
        <svg x={props.x> 20?props.x - 20:0} y={props.y + 24} width={150} height={200} className="gameMenu" data-sub={props.label} data-id={props.i}>
            <rect x='0' y='0' width="144" height="100" onClick={props.closeMenu}></rect>
            <text fill="black" x={5} y={20}>Menu</text>
            <g  className="svgMenuItem" onClick={props.menu.splitPart} ><rect className="svgMenuBorder" x="0" y="30" width="144" height="25"></rect><text x="4" y="48" fill="black">Split Subnet</text></g>
            <g  className="svgMenuItem" onClick={props.menu.mergePart} ><rect className="svgMenuBorder" x="0" y="54" width="144" height="25"></rect><text x="4" y="72" fill="black">Merge Subnet</text></g>
            <g  className="svgMenuItem" onClick={props.menu.dropPart} ><rect className="svgMenuBorder" x="0" y="78" width="144" height="25"></rect><text x="4" y="96" fill="black">Delete Part</text></g>
        </svg>
    );
}