import { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";

export const PlannerForm = (props) => {

    var PLUS = process.env.REACT_APP_HOST + "/img/icons/plusbut.svg";
    const [buttonText, setButtonText] = useState("Submit");

    let initVal = {
        anchorNet: "",
        anchor6Net: "",
        branches: {
            small: {
                count: 1,
                nP2PLinks: 2,
                vlans: [],
            },
            medium: {
                count: 0,
                nP2PLinks: 0,
                vlans: [],
            },
            large: {
                count: 0,
                nP2PLinks: 0,
                vlans: [],
            },
        }
    };

    const setFormat = () => (e) => {
        props.setViewType(e.target.dataset['viewtype']);
        formik.submitForm(e);
      };

    const formik = useFormik({
        initialValues: initVal,
        onSubmit: (values) => {
            postValues(values);
        },
    });

    const clearForm = () => {
        props.showDesc(true);
        if (formik)
          formik.resetForm();
      };

    const postValues = (values) => {
        setButtonText("Working ");
        let formIs = process.env.REACT_APP_HOST + "/api/v1/planner";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        };
        fetch(formIs, requestOptions).then(handleResponse);
    };

    function handleResponse(resp) {
        setButtonText("Submit");
        return resp.text().then((text) => {
            if (resp.ok) {
                props.showDesc(false);
                props.setPlan(JSON.parse(text));
            } else {
                if ([401, 403].includes(resp.status)) {
                    alert("response: " + resp.status);
                }
                const error = resp.statusText;
                return Promise.reject(error);
            } 
            
        });
    }

    return (
        <div className="mr-3">
        <Form className="subform" onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3" controlId="anchorNet">
                <Form.Label>Anchor IPv4 Block</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="IPv4 Global Aggregate"
                    value={formik.values.anchorNet}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus={true}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="anchor6Net">
                <Form.Label>Anchor IPv6 Block</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="IPv6 Global Aggregate"
                    value={formik.values.anchor6Net}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    autoFocus={true}
                />
            </Form.Group>
            <Row>
                <Col className="border border-bl rounded-3 m-2 p-3 bg2">
                    <Form.Group className="mb-3" controlId="branches.small.count">
                        <Form.Label>How many Small branches?</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={1}
                            value={formik.values.branches.small.count}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus={true}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="branches.small.nP2PLinks">
                        <Form.Label>each with n p2p links (/30s)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={1}
                            value={formik.values.branches.small.nP2PLinks}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus={true}
                        />
                    </Form.Group>
                    <Button variant="outline-dark" className="mb-3 mt-3" onClick={() => formik.setFieldValue("branches.small.vlans", [...formik.values.branches.small.vlans, { label: "", size: 0 }])} >Add a vlan
                    <span className="p-3"><img src={PLUS} alt="add"></img></span></Button>
                    {formik.values.branches.small.vlans.map((k, v) => (
                        <Row key={v}>
                            <Col>
                                <Form.Group className="mb-3" controlId={`branches.small.vlans.${v}.label`}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="name"
                                        value={formik.values.branches.small.vlans[v].label}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId={`branches.small.vlans.${v}.size`}>
                                    <Form.Label>Size, IP count</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder={1}
                                        value={formik.values.branches.small.vlans[v].size}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    ))}

                </Col>
                <Col className="border rounded-3 border-bl m-2 p-3 bg2">
                    <Form.Group className="mb-3" controlId="branches.medium.count">
                        <Form.Label>How many Medium branches?</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={0}
                            value={formik.values.branches.medium.count}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus={true}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="branches.medium.nP2PLinks">
                        <Form.Label>Each with n p2p links (/30s)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={0}
                            value={formik.values.branches.medium.nP2PLinks}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus={true}
                        />
                    </Form.Group>
                    <Button variant="outline-dark" className="mt-3 mb-3" onClick={() => formik.setFieldValue("branches.medium.vlans", [...formik.values.branches.medium.vlans, { label: "", size: 0 }])} >Add a vlan 
                    <span className="p-3"><img src={PLUS} alt="add"></img></span></Button>
                    {formik.values.branches.medium.vlans.map((k, v) => (
                        <Row key={v}>
                            <Col>
                                <Form.Group className="mb-3" controlId={`branches.medium.vlans.${v}.label`}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={formik.values.branches.medium.vlans[v].label}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId={`branches.medium.vlans.${v}.size`}>
                                    <Form.Label>Size, IP count</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={formik.values.branches.medium.vlans[v].size}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    ))}
                </Col>

                <Col className="border rounded-3 border-bl m-2 p-3 bg2">
                    <Form.Group className="mb-3" controlId="branches.large.count">
                        <Form.Label>How many Large branches?</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="0"
                            value={formik.values.branches.large.count}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus={true}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="branches.large.nP2PLinks">
                        <Form.Label>Each with n p2p links (/30s)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="0"
                            value={formik.values.branches.large.nP2PLinks}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus={true}
                        />
                    </Form.Group>
                    <Button variant="outline-dark" className="mb-3 mt-3" onClick={() => formik.setFieldValue("branches.large.vlans", [...formik.values.branches.large.vlans, { label: "", size: 0 }])} >Add a vlan
                    <span className="p-3"><img src={PLUS} alt="add"></img></span></Button>
                    {formik.values.branches.large.vlans.map((k, v) => (
                        <Row key={v}>
                            <Col>
                                <Form.Group className="mb-3" controlId={`branches.large.vlans.${v}.label`}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={formik.values.branches.large.vlans[v].label}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId={`branches.large.vlans.${v}.size`}>
                                    <Form.Label>Size, IP count</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="1"
                                        value={formik.values.branches.large.vlans[v].size}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        autoFocus={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    ))}
                </Col>
            </Row>
            <div>
            <Button
              variant={props.viewType === "YAML"? "secondary" : "outline-secondary"}
              onClick={setFormat()}
              data-viewtype="YAML"
            >
              YAML
            </Button>
            <Button
              variant={props.viewType === "JSON" ? "secondary" : "outline-secondary"}
              data-viewtype="JSON"
              onClick={setFormat()}
            >
              JSON
            </Button>
            <Button
              variant={props.viewType === "Table" ? "secondary" : "outline-secondary"}
              data-viewtype="Table"
              onClick={setFormat()}
            >
              Table
            </Button>
          </div>
            <Button className="blbutton" type="submit">
            {buttonText}
            {buttonText === "Working " ? (
              <div className="elipse">
                <span className="ladeda">...</span>
              </div>
            ) : null}
          </Button>
          <Button className="clrbutton" onClick={clearForm}>Clear</Button>
        </Form>
        </div>
    );
};
