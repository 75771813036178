import { useState, useEffect, useRef } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { useHeaderTags } from "../components/custom_hook";
import { Container } from "react-bootstrap";
import { GamePart } from "../components/game_part"
import { PhyContainer } from '../components/physicWrapper';

export const GameFlow = (props) => {

    var [pieces, setPieces] = useState();
    var [clrMenu, setClrMenu] = useState(false);
    var [gameParam, setGameParam] = useState({ "ipv6": false, "level": 0 });
    var [winner, setWinner] = useState(false);
    const [showDesc, setShowDesc] = useState(true);
    var parts = [];
    var partState = useRef([]);
    var gameboard = useRef();
    if (gameboard && gameboard.current)
        var gamebox = gameboard.current.getBoundingClientRect();
    const [positions, setPositions] = useState({})
    const [mergeLine, setMergeLine] = useState({ 'visible': false })
    const [capturedPart, setCapturedPart ] = useState();

    useHeaderTags(props);

    useEffect(() => {
        if (!showDesc)
            postValues(gameParam);
            
    }, [gameParam, showDesc]);

    useEffect(() => {
        function checkOverlaps() {
            partState.current.forEach((part, i) => {
                let coll = checkCollision(part, false);
                if (coll.index) {
                    let hit = coll.result;
                    if (!hit.isBonded()) {
                        hit.reposition(hit.x, part.y1 + 30);
                        let oldPos = { ...positions }
                        // move the hit part out of our way
                        oldPos[coll.index] = hit.position()
                        setPositions(oldPos);
                    }
                }
            });
            updatePositions();
        }
        function captureState() {
            parts.forEach((psvg, i) => {
                let p = psvg.getBBox();
                let c = new PhyContainer(p, psvg.parentElement.dataset.obj, psvg.parentElement.dataset.seq, pieces.gameParts[i].size);
                partState.current.push(c)
            });
        }
        if (pieces) {
            if (partState.current.length === 0)
                captureState();
            checkOverlaps();
        } else {
            reposition();
        }
        if (gameboard.current) {
            reposition();
        }
    }, [pieces]);

    const showBoard = () => {
        setShowDesc(false);
    }

    const reposition = () => {
        if (!gamebox && gameboard.current) {
            gamebox = gameboard.current.getBoundingClientRect();
        }
        let increment = 925 / parts.length;
        let spots = [];
        if (gamebox)
            increment = (gamebox.width - 50) / parts.length;
        for (let i = 0; i < parts.length; i++) {
            spots[i] = { 'x': i * increment, 'y': 5, 'i': i, 'seq': parts[i].seq };
        }
        setPositions(spots);
        updatePositions();
    }

    const zeroAllZ = () => {
        partState.current.forEach(p => p.setZ(0));
        setCapturedPart(null);
    }

    const liftPart = (i, offset) => {
        partState.current[i].setZ(1);
        partState.current[i].setOffset(offset);
        updatePositions();
        setCapturedPart(partState.current[i]);
    }

    const movePart = (box, pos) => {
        let i = box.parentElement.dataset.obj;
        let part = partState.current[i];
        let result = checkCollision(part, true).snap;
        part.updateBoxPos(pos).position();
        updatePositions();
        return result;
    }

    const splitPart = (e) => {
        let formIs = process.env.REACT_APP_HOST + "/api/v1/splitpart";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...pieces, 'splitNet': e.target.parentElement.parentElement.dataset.sub }),
        };
        fetch(formIs, requestOptions).then(handleSplitPart);
    }

    const moveMouse = (e) => {
        if (mergeLine.visible) {
            let spot = { ...mergeLine, 'x2': e.clientX - gamebox.x, 'y2': e.clientY - gamebox.y }
            setMergeLine(spot);
        } else {
            if (capturedPart) {
                capturedPart.mouseMove(e);
                if (checkCollision(capturedPart, true).snap) {
                    zeroAllZ();
                }
                updatePositions();
            }
        }

    }

    const dropMergeLine = (e) => {
        clrAllMenus();
        if (mergeLine.visible) {
            setMergeLine({ 'visible': false });
            let objptrDst = null;
            let partArray = partState.current.filter(partPos => {
                return partPos.containsPoint(e.clientX - gamebox.x, e.clientY - gamebox.y);
            });
            if (partArray.length === 1) {
                objptrDst = partArray[0].i;
            }
            let objptrSrc = mergeLine.srcId;
            if (objptrDst > -1 && partState.current[objptrDst].size === partState.current[objptrSrc].size) {
                acceptMerge(objptrSrc, objptrDst);
            } else {
                rejectMerge(objptrDst, objptrSrc);
            }
        }
    }

    const acceptMerge = (ptr1, ptr2) => {
        let formIs = process.env.REACT_APP_HOST + "/api/v1/mergepart";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...pieces, 'splitNet': ptr1 + ':' + ptr2 }),
        };
        fetch(formIs, requestOptions).then(handleMergePart);
    }

    const rejectMerge = (ptr1, ptr2) => {
        console.log("rejected merge");
    }

    const mergePart = (e) => {
        let srcId = e.target.parentElement.parentElement.dataset.id;
        let part = partState.current[srcId];
        let center = part.getCenter();
        let spot = { 'x1': center[0], 'y1': center[1], 'x2': e.clientX - gamebox.x, 'y2': e.clientY - gamebox.y, 'visible': true, 'srcId': srcId }
        setMergeLine(spot);
        clrAllMenus();
    }

    const dropPart = (e) => {
        let srcId = e.target.parentElement.parentElement.dataset.id;
        let partCtrl = partState.current[srcId];
        partCtrl.disconnect();
        clrAllMenus();
        if (partCtrl.seq >= 0) {
            alert("You'll need this part to cover hop " + (partCtrl.seq + 1));
            return;
        }
        pieces.gameParts.splice(partCtrl.i, 1);
        let parts = [...pieces.gameParts];
        partState.current.splice(partCtrl.i, 1);
        partState.current.forEach((v, i) => v.i = i);
        setPieces({ ...pieces, 'gameParts': parts });
        updatePositions();

    }



    var menu = { 'splitPart': splitPart, 'mergePart': mergePart, 'dropPart': dropPart };

    function handleMergePart(resp) {
        return resp.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!resp.ok) {
                if ([401, 403].includes(resp.status)) {
                    alert("response: " + resp.status);
                }

                const error = (data && data.message) || resp.statusText;
                return Promise.reject(error);
            }
            if (data.splitNet[0] < 0 || data.splitNet[1] < 0) {
                rejectMerge(-1 * data.splitNet[0], -1 * data.splitNet[1]);
                return;
            }
            let root = partState.current[data.splitNet[0]];
            if (root.seq < 0) {
                root.seq = partState.current[data.splitNet[1]].seq;
            }
            data.splitNet.forEach(p => {
                partState.current[p].disconnect();
            });
            partState.current.splice(data.splitNet[1], 1);
            partState.current.forEach((v, i) => v.i = i);
            root.doubleUp();
            setPieces(data);
            updatePositions();
        })
    }
    function handleSplitPart(resp) {
        return resp.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!resp.ok) {
                if ([401, 403].includes(resp.status)) {
                    alert("response: " + resp.status);
                }

                const error = (data && data.message) || resp.statusText;
                return Promise.reject(error);
            }
            let i = data.chgPtr;
            partState.current[i].disconnect();
            if (data.gameParts.length > partState.current.length) {
                let child1 = PhyContainer.getHalfCopy(partState.current[i]);
                child1.seq = data.gameParts[i].seq;
                let child2 = PhyContainer.getHalfCopy(partState.current[i]);
                child2.seq = data.gameParts[i + 1].seq;
                child2.i = data.gameParts.length - 1;
                if (child2.width === child2.height) {
                    child2.x = child1.x1 + 3;
                    child2.x1 = child2.x + child2.width;
                } else {
                    child2.y = child2.y1 + 3;
                    child2.y1 = child2.y + child2.height;
                }
                partState.current.splice(i, 1, child1, child2);
                partState.current.forEach((v, i) => v.i = i);
                setPieces(data);
            } else {
                alert("Can't go smaller than a /29")
            }
            updatePositions();
            clrAllMenus();
        });
    }

    const clrAllMenus = () => {
        setClrMenu((clrMenu) => { setClrMenu(~clrMenu) });
    }

    const updatePositions = () => {
        let topPart = partState.current.filter(p => p.z === 1).map(s => { return s.position() });
        let bottomParts = partState.current.filter(p => p.z !== 1).map(s => { return s.position() });
        bottomParts.push(...topPart);
        setPositions({ ...bottomParts });
    }

    const checkCollision = (part, Ok2snap) => {
        let result = null;
        let index = null;
        let snap = false;
        partState.current.filter(p => { return p !== part }).forEach(p => {
            if (part.touches(p)) {
                result = p;
                index = p.i;
                if (Ok2snap) {
                    snap = part.snapOnX(p);
                    if (snap) {
                        setWinner(part.hopCount() === 6)
                    }
                }
            }
        });
        return { result, index, snap };
    }

    const score = () => {
        return partState.current.reduce((p, part) => {
            let c = p.cost + part.score();
            let h = p.hopCount;
            if (part.score() > 0) {
                h++;
            }
            return { 'cost': c, 'hopCount': h }
        }, { 'cost': 0, 'hopCount': 0 });
    }

    const postValues = (values) => {
        let formIs = process.env.REACT_APP_HOST + "/api/v1/gameparts";
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values),
        };
        fetch(formIs, requestOptions).then(handleResponse);
    };

    function handleResponse(resp) {
        return resp.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!resp.ok) {
                if ([401, 403].includes(resp.status)) {
                    alert("response: " + resp.status);
                }

                const error = (data && data.message) || resp.statusText;
                return Promise.reject(error);
            }
            setPieces(data);
            let spots = {};
            let increment = 0;
            if (gamebox)
                increment = (gamebox.width - 50) / data.gameParts.length;
            else
                increment = 925 / data.gameParts.length;
            for (let i = 0; i < data.gameParts.length; i++) {
                spots[i] = { 'x': i * increment, 'y': 5, 'i': i, 'seq': data.gameParts[i].seq };
            }
            setPositions(spots);
        });
    }
    let theScore = score();
    Object.values(positions).forEach(p => {
        let pstate = partState.current[p.i];
        if (pstate) {
            p.stacked = partState.current[p.i].stacked;
            if (pstate.isBonded()) {
                p.bonded = true;
            }
        }
    })
    let pool = pieces ? pieces.pointsPool : 0;
    let finish = pool === theScore.cost && winner;
    return (
        <Container>
            <Row className="paper">
                <Col><h4>Trace Route:</h4><ol>
                    {pieces && pieces.traceHops.map(h => {
                        return <li key={h}>&nbsp;&nbsp;{h}</li>
                    })}
                </ol></Col>
                <Col><h4>Score</h4>
                    <div>Goal: &nbsp;{pool}</div>
                    <div>Cost: &nbsp;{theScore.cost}</div>
                    <div>Hop Count: {theScore.hopCount}</div>
                    {winner && pool === theScore.cost ?
                        <div className="text-success text-bold">NICE!</div> :
                        <div className="text-deny">Difference: {pool - theScore.cost}</div>
                    }
                    </Col>
            </Row><Row ref={gameboard}>
            {!showDesc && positions && pieces && <svg height="1000px" width="100%" className="gameBorder" onMouseMove={moveMouse} onMouseUp={dropMergeLine}>
                {Object.values(positions).map(pos => {
                    return <GamePart finish={finish} clrMenu={clrMenu} spot={pos} key={pos.i} part={pieces.gameParts[pos.i]} menu={menu} liftPart={liftPart} dropPart={zeroAllZ} ref={ref => parts[pos.i] = ref} />
                })}
                {mergeLine.visible && <>
                    <line x1={mergeLine.x1} x2={mergeLine.x2} y1={mergeLine.y1} y2={mergeLine.y2} stroke="yellow"></line>
                    <circle cx={mergeLine.x1} cy={mergeLine.y1} r='3' fill='yellow'></circle>
                    <circle cx={mergeLine.x2} cy={mergeLine.y2} r='3' fill='yellow'></circle>
                </>}
            </svg>
            }</Row>
            {showDesc && (
                <Row>
                    <div className="docs paper">
                        <h3>Description</h3>
                        <p>
                            Use your skills to complete this subnet puzzle. Study the hosts in the trace route shown above. 
                            Then connect the subnets that cover these hosts from left to right.
                        </p>
                        <p>
                        Much like routing metrics, each hop of the path has an associated cost. For the purpose of this game, that cost is based on the size of the subnet.  For example, a /24
                            subnet is 256. Decide which subnets cover the hops in the trace route.  Then connect the subnets 
                            by placing them from left to right, in the order they appears in the trace route.  The sum of the costs of each hop is the path cost.  The objective is to get the path cost equal the goal shown.
                        </p>
                        <h3>How to Play</h3>
                        <p>
                            Arrange the subnets that cover the trace route in the correct order. The parts will snap together if they are correct.  
                            You can hover over the hamburger icon to view the size of a subnet and it's mask in dot notation.  You can click on the icon to get a menu that allows some actions 
                            to be preformed on the subnet.  In some cases,
                            the subnet may be larger than what is needed to reach the goal. It can be split into two 
                            smaller subnets. Use the one that covers the hop in the trace route.  In other cases, you may be able to 
                            merge two subnets into a larger subnet to help get the path cost closer to the goal.  Choose the merge option, and connect the line to the part you'd like to merge with.
                            In order to merge two subnets, each must share the same network bits after the mask as been moved one bit to the left, and of course, they will need the same mask value.  You can't merge a /25 with a /26.
                            </p><p>
                            The shape and size of the boxes shown is based on the subnets size.  Subnets with an even CIDR, ie: /24, /26, /28 will be a squares because 
                            it turns out that their size is perfect square.  /24 = 256, /26 = 64, /28 = 16. Odd CIDRs are 
                            rectangles.  
                        </p>
                        <h4>Notes</h4>
                        <li>The game works best on desktops with a screen width of 1024 or better</li>
                        <Button className="blbutton" onClick={showBoard}>Play

                        </Button>
                    </div>
                </Row>
            )}
        </Container>
    )
}