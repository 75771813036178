import { useLayoutEffect } from "react"

export const SvgCanvas = (props) => {


    const getHandles = (box) => {
        let x = box.x - c.x;
        let y = box.y - c.y;
        return {
            left_x: x,
            left_y: y + box.height / 2,
            right_x: x + box.width,
            right_y: y + box.height / 2,
            top_x: x + box.width / 2,
            top_y: y,
            bottom_x: x + box.width / 2,
            bottom_y: y + box.height
        }
    }

    useLayoutEffect(() => {
        props.forceRender();
    }, [props.subnet]);

    if (!props.canvasRef) {
        return null;
    }
    let roots = [];
    let branches = [];
    let lines = [];
    var c = null;
    if (props.canvasRef.current) {
        c = props.canvasRef.current.getBoundingClientRect();
    }
    props.treeRef.current.forEach(t => {
        if (t) {
            if (t.dataset.pos?.includes(" of ")) {
                let num = t.dataset.pos.split(" of ")[0];
                let dem = t.dataset.pos.split(" of ")[1];
                let startPoint = {
                    handles: getHandles(t.getBoundingClientRect()),
                    num: num,
                    dem: dem
                }
                roots.push(startPoint);
            }
        }

    });

    props.branchRef.current.forEach(b => {
        if (b) {
            let endPoint = {
                handles: getHandles(b.getBoundingClientRect()),
                spot: Number(b.dataset.spot),
            };
            branches.push(endPoint);
        }

    });
    roots.forEach(r => {
        let i = 0;
        for (i; i < branches.length; i++) {
            if (branches[i].spot === 0) {
                break;
            } 
        }
        let center = i >= branches.length? 0 : i + 1;
        let rightRage = branches.length - center;
        let leftRun =  Number(r.num - 1 > center ? center : r.num - 1);
        let leftArrow = r.num - 1 > center ? true : false;
        let rightRun = Number(r.dem - r.num < rightRage ? r.dem - r.num : rightRage);
        let rightArrow = r.dem - r.num <= rightRage ? false : true;
        let leftRange = [center - leftRun, center];
        let rightRange = [center, center + rightRun];
        if (r.handles) {
            for (let i = leftRange[0]; i < leftRange[1]; i++) {
                let b = branches[i];
                if (b && b.handles && r.handles.left_x > b.handles.top_x) {
                    lines.push({
                        x1: r.handles.left_x,
                        y1: r.handles.left_y,
                        x2: b.handles.top_x,
                        y2: b.handles.top_y,
                        larw: leftArrow,
                    });
                }
            };

            for (let i = rightRange[0]; i < rightRange[1]; i++) {
                let b = branches[i];
                if (b && b.handles && r.handles.right_x < b.handles.top_x) {
                    lines.push({
                        x1: r.handles.right_x,
                        y1: r.handles.right_y,
                        x2: b.handles.top_x,
                        y2: b.handles.top_y,
                        rarw: rightArrow
                    });
                }
            };
        }
    });
    let center = props.centerRef.current ? getHandles(props.centerRef.current.getBoundingClientRect()) : null;
    let bottom = roots.length > 0 ? roots[roots.length - 1].handles : null;
    return (
        <svg id="chartCanvas" className="chart_canvas">
            {lines.length > 0 && lines.map((connector, i) => {
                return <>
                    <line className="chart_line" key={i} x1={connector.x1} x2={connector.x2} y1={connector.y1} y2={connector.y1} stroke="black" />
                    <line className="chart_line" key={i + lines.length} x1={connector.x2} x2={connector.x2} y1={connector.y1} y2={connector.y2} stroke="black" />
                    {connector.rarw && <>
                        <line x1={connector.x1} x2={c.width} y1={connector.y1} y2={connector.y1} stroke="black" />
                        <polygon points={`${c.width}, ${connector.y1} ${c.width - 10}, ${connector.y1 - 5} ${c.width - 10}, ${connector.y1 + 5}`} />
                    </>}
                    {connector.larw && <>
                        <line x1="0" x2={connector.x2} y1={connector.y1} y2={connector.y1} stroke="black" />
                        <polygon points={`${0}, ${connector.y1} ${10}, ${connector.y1 - 5} ${10}, ${connector.y1 + 5}`} />
                    </>}
                </>
            })}
            {center && bottom && <line className="chart_line" x1={center.top_x} x2={bottom.bottom_x} y1={center.top_y} y2={bottom.bottom_y} stroke="black" />}
            {roots.filter((t, i) => { return i > 0 }).map((r, i) => {
                return <line key={roots.length * 2 + i} x1={r.handles.bottom_x} x2={roots[i].handles.top_x} y1={r.handles.top_y} y2={roots[i].handles.bottom_y} stroke="black" />
            })}
        </svg>
    )
}