export const SubnetItem = (props) => {
    let subnet = props.subnet;
    

    const nSpaces = (n) => {
        if (n === 0) {
            return;
        }
        return (
            "  ".repeat(n) + "∟-"
        );
    };

    const nMerged = (n) => {
        if (n === 0) {
            return;
        }
        if (n === 1) {
            return " \\";
        }
        return " /";
    };

    return (
        <div key={props.id}>
            <span>{subnet.merged > 0 ? null : nSpaces(subnet.level)}</span>
            {subnet.root}
            {subnet.merged > 0 ? nMerged(subnet.merged) : null}
            {subnet.seq && <span className="text-muted"> (seq {subnet.seq})</span>}
            {subnet.desc && <span className="text-muted"> {subnet.desc}</span>}
        </div>
    );
}