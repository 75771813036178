import { React, useState, useEffect, useRef } from 'react';
import { useFormik } from "formik";
import { Form, Button } from "react-bootstrap";

export const RouteForm = (props) => {

    let initVal = {
        lines: ""
    }

    const [ buttonText, setButtonText ] = useState("Submit")

    const clearForm = () => {
      props.showHelp(true);
      if (formik)
        formik.resetForm();
    };

    useEffect(() => {
      if (props.prePopulate) {
        formik.submitForm();
      }
    }, []);

    const postValues = (values) => {
        if (!values.lines || typeof(values.lines) !== "string") return;
        let formIs = process.env.REACT_APP_HOST+"/api/v1/route";
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values),
        };
        fetch(formIs, requestOptions).then(handleResponse);
        setButtonText("Working ");
    };

    const formik = useFormik({
        initialValues: initVal,
        onSubmit: (values) => {
          postValues(values);
        },
      });
  

    function handleResponse(resp) {
      setButtonText("Submit");
      if (!resp.ok) {
        alert("The server ran into trouble with this route table. Try a different table, or check back in a few days");
      }
    return resp.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!resp.ok) {
        if ([401, 403].includes(resp.status)) {
            alert("response: " + resp.status);
        } 
        const error = (data && data.message) || resp.statusText;
        return Promise.reject(error);
        }
        props.setRoutes(data);
        props.showHelp(false);
        props.refresh();
    });
    }
   
    return (
        <Form className="subform" onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId="lines">
          <Form.Label>Routes</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            rows="6"
            placeholder="ipv4 or ipv6 routes"
            value={formik.values.lines}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoFocus={true}
            aria-describedby="prefixDialog"
          />
        </Form.Group>
        <Button className="blbutton" type="submit">
          {buttonText}{buttonText==='Working '?(<div className="elipse">
                <span className="ladeda">...</span>
              </div>):null}
        </Button>
        <Button className="clrbutton" onClick={clearForm}>Clear</Button>
      </Form> 
    )
}