import { Fragment } from 'react';
import { Col, Container, Table } from 'react-bootstrap';
import YAML from "yaml";

export const PlannerView = (props) => {

    let outText = "";
    switch (props.viewType) {
        case "JSON":
            outText = JSON.stringify(props.plan, null, 2);
            break;
        case "YAML":
            outText = YAML.stringify(props.plan, { options: { sortMapEntries: true } });
            break;
        case "Table":
            break;
        default:
            outText = "";
    }

    const vlanCell = (vlans) => {
        let cell = vlans[0];
        return <><td>{cell.label}</td><td>{cell.ipv4}</td>{cell.ipv6 ? <td>{cell.ipv6}</td>:<td></td>}</>;
    }

    const vlanCarry = (vlans) => {
        if (vlans.length > 1) {
            return vlans.slice(1).map(cell => {
                return <tr key={cell.label}><td></td><td></td><td></td><td></td><td>{cell.label}</td><td>{cell.ipv4}</td>{cell.ipv6 ? <td>{cell.ipv6}</td>:<td></td>}</tr>
            });
        }

    }

    return (
        <Container className="docs">
            {(props.viewType === "JSON" || props.viewType === "YAML") ? (
                <Col>
                    <pre className="half-panel">
                        <code>{outText}</code>
                    </pre>
                </Col>) : (
                <Table striped bordered variant="light" size="sm" >
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>Branch</th>
                            <th>Branch Agg</th>
                            <th>Infra Agg</th>
                            <th>Vlans</th>
                            <th>IPv4</th>
                            <th>IPv6</th>
                        </tr>
                    </thead>
                    { props.plan.branches &&
                    <tbody>
                        {props.plan.branches.small.length > 0 && <>
                            <tr className="upperoine"><td>Total IPv4</td><td></td><td>{props.plan.totalIpv4Demand}</td><td></td><td></td><td></td><td></td></tr>
                            <tr className="upperline"><td>Small</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                            {props.plan.branches.small.map(b => {
                                return (<Fragment key={b.name}>
                                    <tr key={b.name}><td></td><td>{b.name}</td><td>{b.branchAggregate}</td><td>{b.p2pAggregate}</td>
                                    {b.vlans.length > 0 ? vlanCell(b.vlans) : <><td></td><td></td><td></td></>}</tr>
                                    {vlanCarry(b.vlans)}
                                </Fragment>);

                            })}
                        </>}
                        {props.plan.branches.medium.length > 0 && <>
                            <tr className="upperline"><td>Medium</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                            {props.plan.branches.medium.map(b => {
                                return <Fragment key={b.name}><tr key={b.name}><td></td><td>{b.name}</td><td>{b.branchAggregate}</td><td>{b.p2pAggregate}</td>
                                    {b.vlans.length > 0 ? vlanCell(b.vlans) : (
                                        <td></td>
                                    )}</tr>
                                    {vlanCarry(b.vlans)}
                                </Fragment>

                            })}
                        </>}
                        {props.plan.branches.large.length > 0 && <>
                            <tr className="upperline"><td>Large</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>
                            {props.plan.branches.large.map(b => {
                                return <Fragment key={b.name}><tr key={b.name}><td></td><td>{b.name}</td><td>{b.branchAggregate}</td><td>{b.p2pAggregate}</td>
                                    {b.vlans.length > 0 ? vlanCell(b.vlans) : (
                                        <td></td>
                                    )}</tr>
                                    {vlanCarry(b.vlans)}
                                </Fragment>

                            })}
                        </>}
                    </tbody>}
                </Table>
            )}
        </Container>
    );
}