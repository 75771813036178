import { useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import { Bitmap } from "../components/bitmap";

export const QuizChoice = (props) => {

    const [bits, setBits] = useState();
    const bitRef = useRef();

    const toggleSelect = () => {
        props.toggle(props.index);
        if (props.option.element) {
            setBits(props.option.element);
        } else {
            setBits(null);
        }
    }
    let width = bitRef.current?bitRef.current.getBoundingClientRect().width:0;
    let mask = 1 << props.index;
    let selected = (props.chosen[0] & mask) > 0 ? true : false;
    let green = (props.chosen[1] & mask) > 0 ? true : false;
    let red = (props.chosen[2] & mask) > 0 ? true : false;
    let color = null;
    if (green) {
        color = "correct";
    } else if (red) {
        color = "wrong";
    }

    let labels = ['A', 'B', 'C', 'D', 'E', 'F']

    return (
        <Row>
            <Col className={color}>
                <div className={selected ? "quizChoiceSelected" : "freeChoice"}>
                    <div className="quiz">
                        <div onClick={toggleSelect} className={selected?"qlclicked clickable":"quizLabel clickable"}>{labels[props.index]}</div>
                        <div onClick={toggleSelect} className="quizChoice">{props.option.text}</div>
                    </div>
                </div></Col>
            <Col ref={bitRef}>{bits && selected?<Bitmap subnet={bits} width={width}/>:null}</Col>
        </Row>
    )
}