import { React } from "react";
import { Card, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const ToolSelector = (props) => {
  let binocImg = process.env.REACT_APP_HOST + "/img/icons/icon61.svg";
  let fourin6 = process.env.REACT_APP_HOST + "/img/icons/fourin6.svg";
  let prefixImg = process.env.REACT_APP_HOST + "/img/icons/icon67.svg";
  let allocateImg = process.env.REACT_APP_HOST + "/img/icons/pieslice.svg";
  let patternImg = process.env.REACT_APP_HOST + "/img/icons/pattern.svg";
  let vennImg = process.env.REACT_APP_HOST + "/img/icons/venn.svg";
  let treeImg = process.env.REACT_APP_HOST + "/img/icons/subtree.svg";
  let plannerImg = process.env.REACT_APP_HOST + "/img/icons/planner.svg";
  let trophyImg = process.env.REACT_APP_HOST + "/img/icons/icon76.svg";
  let routeImg = process.env.REACT_APP_HOST + "/img/icons/icon70.svg";
  let practiceImg = process.env.REACT_APP_HOST + "/img/icons/icon94.svg";

  const navigate = useNavigate();
  props.sidebar(false);

  return (
    <Row className={props.wideview?"shiftleft":"kidrow"}>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/subnet.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Subnet Calculator</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Graphical Tree
          </Card.Subtitle>
          <Card.Text>
            Subnet size, start and end IP addresses, supernets, subnets and adjacent nets
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={binocImg} alt="Graphical Subnet" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/prefix.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Prefix List</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            from subnets
          </Card.Subtitle>
          <Card.Text>
            Convert a list of subnets into a prefix-list. Find subnet overlaps or summerize subnets
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={prefixImg} alt="Summary Prefix" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/tree.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Tree</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Subnet Structured Tree
          </Card.Subtitle>
          <Card.Text>
            Organize subnets into a data structure
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={treeImg} alt="IP Tree" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/diffs.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Diffs</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Subnet Coverage Diffs
          </Card.Subtitle>
          <Card.Text>
            Compare two lists of subnets for common and distinct prefix coverage
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={vennImg} alt="IP Diffs" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/routes.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Routes</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Route Analyzer
          </Card.Subtitle>
          <Card.Text>
            Improve the performance of a route table. Identify potential summaries
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={routeImg} alt="Route Table tool" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/planner.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Planner</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            WAN Aggregation Planner
          </Card.Subtitle>
          <Card.Text>
            Allocate subnets over the branch network based on defined criteria.
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={plannerImg} alt="WAN Aggregate plan" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/embed.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Embed IPv4 in IPv6</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            rfc6052 NAT64 Converter
          </Card.Subtitle>
          <Card.Text>
            Translate IPv4 to IPv6 using this IP NAT64 encoder and decoder.
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={fourin6} alt="IPv4 embedded in IPv6" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/allocate.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Allocate Subnets</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Best match with constraints
          </Card.Subtitle>
          <Card.Text>
            Allocate subnets to cover a range of IPs with minimal waste.
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={allocateImg} alt="Address Planner" />
      </Card>
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/pattern.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Patterns</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Network Design Patterns
          </Card.Subtitle>
          <Card.Text>
            Allocate subnets over common design patterns such as Leaf Spine.
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={patternImg} alt="Subnet Components" />
      </Card>  
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/practice.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Practice</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Cert Practice
          </Card.Subtitle>
          <Card.Text>
            Practice subnet questions to get you in the grove
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={practiceImg} alt="Subnet Practice" />
      </Card>
      {window.visualViewport.width > 1000 &&
      <Card style={{ width: "18rem" }} className="m-3 toolcard" text="grey" onClick={() => navigate("/act/app/challenge.html")} role="button" tabIndex="0">
        <Card.Body>
          <Card.Title>Challenge</Card.Title>
          <Card.Subtitle className="mb-2 text-contrast">
            Subnet Puzzle
          </Card.Subtitle>
          <Card.Text>
            A challenge to solidify your understanding of subnetting
          </Card.Text>
        </Card.Body>
        <Card.Img width="18rem" height="100%" variant="bottom" src={trophyImg} alt="Game Play" />
      </Card>}
    </Row>
  );
};
